import { PageNames } from "../../pages/PageNames";
import { ISiteVisualInfo } from "../sites/SiteModels";

export const isPageVisible = (
  pageName: string,
  siteVisualInfo: ISiteVisualInfo
) => {
  if (!(pageName in PageNames)) {
    return false;
  }
  switch (pageName) {
    case PageNames.Agriculture:
      return siteVisualInfo.isAgriPageVisible;
    case PageNames.Sprinklers:
      return siteVisualInfo.isEnableSprinklersFeature;
    case PageNames.Weather:
      return siteVisualInfo.isEnableWeatherFeature;
    case PageNames.UserRecords:
      return siteVisualInfo.isEnableUserRecordsFeature;
    case PageNames.Alerts:
      return siteVisualInfo.isEnableIssuesFeature;
    default:
      return true;
  }
};
