import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import DateTimeFormatter from "../../features/app/DateTimeFormatter";

export function createTimeCell<T extends GridValidRowModel>(
  valueGetter: (row: T) => number, formatter: DateTimeFormatter, isSmallScreen: boolean, includeSeconds = false
): Partial<GridColDef<T>> {
  let width: number;
  if (includeSeconds) {
    width = isSmallScreen ? 150 : 190;
  } else {
    width = isSmallScreen ? 120 : 150;
  }
  return {
    ...createFormattedCell((params) => {
      const value = valueGetter(params);
      return { value, formatted: formatter.formatDateAndTime(value, includeSeconds) };
    }),
    width
  };
}

export function createFormattedCell<T extends GridValidRowModel>(
  valueGetter: (row: T) => { value: number, formatted: string }
): Partial<GridColDef<T>> {
  return {
    valueGetter: (params) => {
      const { value, formatted } = valueGetter(params.row);
      return new FormattedValueBox(value, formatted);
    },
    valueFormatter: (params) => params.value.formattedValue,
    renderCell: (params) => params.formattedValue,
    sortComparator: (v1: FormattedValueBox, v2: FormattedValueBox) => v1.rawValue - v2.rawValue
  };
}

class FormattedValueBox {
  constructor(public rawValue: number, public formattedValue: string) { }
  toString() {
    return this.formattedValue;
  }
}