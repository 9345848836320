import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/Store";
import DataPointTooltip from "../data_point/wrapper/DataPointTooltip";

interface TagHeaderTooltipProps {
    tagName: string;
    displayName?: string;
    children?: React.ReactNode;
}

const TagHeaderTooltip: FC<TagHeaderTooltipProps> = ({ tagName, displayName, children }) => {
    const sitesMetadata = useSelector((state: RootState) => state.multiSitesMetadata.sites);
    // find first site that has this tag:
    const [siteId] = Object.entries(sitesMetadata).find(([, metadata]) => tagName in metadata.tags) ?? [];
    if (siteId === undefined) {
        // edge case where no site has this tag, no need to render tooltip
        return <></>;
    }
    return (
        <DataPointTooltip
            tagName={tagName}
            siteId={siteId}
            displayName={displayName}
            isShowDateOnly={false}
            isShowTagMetadataOnly={true}
        >
            {children}
        </DataPointTooltip>
    )
}

export default TagHeaderTooltip;