import { FC } from "react";
import { useSelector } from "react-redux";
import { TagTimeValidity } from "./models/TagsModels";
import { RootState } from "../app/Store";
import { selectSiteTag } from "../app/store/MultisiteTagsStore";
import InvalidIcon from "../html_elements/InvalidIcon";

interface TagInvalidIconProps {
  tagName: string;
  siteId: string;
}

const TagInvalidIcon: FC<TagInvalidIconProps> = ({ tagName, siteId }) => {
  const valueTime = useSelector((state: RootState) => selectSiteTag(state, siteId, tagName));
  const isInvalid = !!(valueTime.valueExist && valueTime.timeValidity == TagTimeValidity.ValidButOld);
  return <InvalidIcon showInvalid={isInvalid} />;
};

export default TagInvalidIcon;
