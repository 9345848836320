import { GridColDef } from "@mui/x-data-grid";
import { DeleteModuleType } from "./DeleteModuleType";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { ModuleType } from "./models";
import { NO_VALUE_PLACEHOLDER } from "../../../common/ConstantValues";

const moduleTypeFormatter = (value: number) =>
  humanizeEnumValue(ModuleType[value]) ?? NO_VALUE_PLACEHOLDER;

export const createModuleMetadataTableColumns = (onDelete: () => void) => {
  const tableColumns: GridColDef[] = [
    {
      field: "DeviceId",
      headerName: "Device Id",
      flex: 1,
    },
    {
      field: "Type",
      headerName: "Module type",
      flex: 1,
      valueGetter: (params) => moduleTypeFormatter(params.row.Type),
    },
    {
      field: "SiteId",
      headerName: "Delete",
      flex: 0.3,
      renderCell: (params) => {
        const type = moduleTypeFormatter(params.row.Type);
        return (
          <DeleteModuleType
            siteId={params.row.SiteId}
            type={type}
            deviceId={params.row.DeviceId}
            onDelete={onDelete}
          />
        );
      },
    },
  ];
  return tableColumns;
};
