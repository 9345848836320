import { useEffect, useState } from "react";
import { sub } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import UserRecordsTableUi from "./UserRecordsTableUi";
import { fetchUserRecords, selectCurrentSiteFormattedUserRecords } from "../../app/store/UserRecordsStore";
import { selectSiteId } from "../../sites/SiteStore";
import { AppDispatch, RootState } from "../../app/Store";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";

const UserRecordTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const siteId = useSelector(selectSiteId);
  const usersRecordsState = useSelector((state: RootState) => state.multiSitesUserRecords[siteId]);
  const formattedUserRecords = useSelector(selectCurrentSiteFormattedUserRecords);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  useEffect(() => {
    const creationTimeEnd = new Date();
    const creationTimeStart = sub(creationTimeEnd, { months: 3 });
    dispatch(fetchUserRecords({ siteId, creationTimeStart, creationTimeEnd }));
  }, [siteId]);

  useEffect(() => {
    if (usersRecordsState?.error) {
      setAlertMessage({
        text: usersRecordsState.error,
        severity: "error",
      });
    } else {
      setAlertMessage(undefined);
    }
  }, [usersRecordsState]);
  return (
    <div>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      {usersRecordsState && <UserRecordsTableUi data={formattedUserRecords} isLoading={usersRecordsState.loading} />}
    </div>
  );
};
export default UserRecordTable;