import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TrackersControlCommandFormUi from "./TrackersControlCommandFormUi";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { multiSitesTrackersCommandsSlice } from "../../app/store/TrackersCommandsStore";
import IconLoader from "../../html_elements/IconLoader";
import {
  sendTrackersToMaintenance,
  releaseTrackersFromMaintenance,
  sendTrackersToFreeze,
  resetTrackers,
  sendTrackersToHoming,
  forceTrackersElevation,
} from "../TrackersAPI";
import {
  ElevationDirections,
  HomingDirection,
  TrackerElevation,
  TrackersCommandType,
} from "../TrackersModels";
import { selectSiteId } from "../../sites/SiteStore";

interface TrackersControlFormProps {
  selectedTrackerIds: number[];
  afterSendCommand: () => void;
  disabled: boolean;
}

const TrackersControlCommandForm = ({
  selectedTrackerIds,
  afterSendCommand,
  disabled,
}: TrackersControlFormProps) => {
  const dispatch = useDispatch();
  const siteId = useSelector(selectSiteId);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [command, setCommand] = useState<TrackersCommandType>(
    TrackersCommandType.SendTrackersToMaintenance
  );

  const [elevation, setElevation] = useState<TrackerElevation>({
    value: 0,
    direction: ElevationDirections.West,
  });
  const [reason, setReason] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedTrackerIds.length === 0) {
      setMessageToError("No trackers selected");
      return;
    }
    setAlertMessage(undefined);
    setShowLoader(true);
    switch (command) {
      case TrackersCommandType.SendTrackersToMaintenance:
        if (!reason) {
          setMessageToError("Reason is required");
          setShowLoader(false);
          return;
        }
        await handleSendTrackersToMaintenance();
        break;
      case TrackersCommandType.ReleaseTrackersFromMaintenance:
        await handleReleaseTrackersFromMaintenance();
        break;
      case TrackersCommandType.SendTrackersToFreeze:
        if (!reason) {
          setMessageToError("Reason is required");
          setShowLoader(false);
          return;
        }
        await handleSendTrackersToFreeze();
        break;
      case TrackersCommandType.ResetTrackers:
        await handleResetTrackers();
        break;
      case TrackersCommandType.SendTrackersToHoming:
        await handleSendTrackersToHoming();
        break;
      case TrackersCommandType.ForceTrackersElevation:
        await handleForceTrackersElevation();
        break;
      default:
        setMessageToError("Command is required");
        setShowLoader(false);
        return;
    }
    setShowLoader(false);
    afterSendCommand();
  };

  const handleSendTrackersToHoming = async () => {
    const commandResult = await sendTrackersToHoming(
      siteId,
      selectedTrackerIds,
      HomingDirection[elevation.direction]
    );
    dispatch(
      multiSitesTrackersCommandsSlice.actions.setTrackersCommandResult(
        {
          site: siteId,
          result: {
            result: commandResult,
            commandType: TrackersCommandType.SendTrackersToHoming,
            homingDirection: HomingDirection[elevation.direction]
          },
        }
      )
    );
    setAlertMessage(undefined);
  }

  const handleResetTrackers = async () => {
    const commandResult = await resetTrackers(
      siteId,
      selectedTrackerIds
    );
    dispatch(
      multiSitesTrackersCommandsSlice.actions.setTrackersCommandResult(
        {
          site: siteId,
          result: {
            result: commandResult,
            commandType: TrackersCommandType.ResetTrackers,
          },
        }
      )
    );
    setAlertMessage(undefined);
  }

  const handleReleaseTrackersFromMaintenance = async () => {
    const commandResult = await releaseTrackersFromMaintenance(
      siteId,
      selectedTrackerIds
    );
    dispatch(
      multiSitesTrackersCommandsSlice.actions.setTrackersCommandResult(
        {
          site: siteId,
          result: {
            result: commandResult,
            commandType: TrackersCommandType.ReleaseTrackersFromMaintenance,
          },
        }
      )
    );
    setAlertMessage(undefined);
  };

  const handleSendTrackersToFreeze = async () => {
    if (!reason) {
      setMessageToError("Reason is required");
      return;
    }
    const commandResult = await sendTrackersToFreeze(
      siteId,
      selectedTrackerIds,
      reason
    );
    dispatch(
      multiSitesTrackersCommandsSlice.actions.setTrackersCommandResult(
        {
          site: siteId,
          result: {
            result: commandResult,
            commandType: TrackersCommandType.SendTrackersToFreeze,
            reason: reason
          },
        }
      )
    );
    setAlertMessage(undefined);
  };

  const handleSendTrackersToMaintenance = async () => {
    if (elevation === null) {
      setMessageToError("Elevation is required");
      return;
    }
    if (!reason) {
      setMessageToError("Reason is required");
      return;
    }
    const commandResult = await sendTrackersToMaintenance(
      siteId,
      selectedTrackerIds,
      elevation,
      reason
    );
    const elevationZenith = elevation.value * (elevation.direction === ElevationDirections.West ? 1 : -1);
    dispatch(
      multiSitesTrackersCommandsSlice.actions.setTrackersCommandResult(
        {
          site: siteId,
          result: {
            result: commandResult,
            commandType: TrackersCommandType.SendTrackersToMaintenance,
            elevation: elevationZenith,
            reason: reason,
          }
        }
      )
    );
    setAlertMessage(undefined);
  };

  const handleForceTrackersElevation = async () => {
    if (elevation === null) {
      setMessageToError("Elevation is required");
      return;
    }
    const commandResult = await forceTrackersElevation(
      siteId,
      selectedTrackerIds,
      elevation,
    );
    const elevationZenith = elevation.value * (elevation.direction === ElevationDirections.West ? 1 : -1);
    dispatch(
      multiSitesTrackersCommandsSlice.actions.setTrackersCommandResult(
        {
          site: siteId,
          result: {
            result: commandResult,
            commandType: TrackersCommandType.ForceTrackersElevation,
            elevation: elevationZenith,
          }
        }
      )
    );
    setAlertMessage(undefined);
  };

  const setMessageToError = (text: string) => {
    setAlertMessage({ text: text, severity: "error" });
  };


  return (
    <IconLoader isLoading={showLoader}>
      <TrackersControlCommandFormUi
        command={command}
        setCommand={setCommand}
        elevation={elevation}
        setElevation={setElevation}
        reason={reason}
        setReason={setReason}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        handleSubmit={handleSubmit}
        disabled={disabled}
      />
    </IconLoader>
  );
};
export default TrackersControlCommandForm;
