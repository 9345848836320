import { FC } from 'react';
import { parse } from '@messageformat/parser';
import { ComponentType, IAlertMetadata, UrgencyLevel, SafeReason } from '../../DTOs';
import { AlertUnion } from '../../Models';
import { TrackerNameById } from '../../../trackers/TrackerName';
import classes from './DescriptionCell.module.css';
import LightTooltipOnEllipsis from "../../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import { humanizeEnumValue } from '../../../../common/EnumUtils';
import { AlertDescriptionTokenFormatter, formatMinutesAgo, fractionToPercentage } from '../../Utils';

interface IAlertDescriptionCellProps {
    alert: AlertUnion;
    metadata: IAlertMetadata;
}

export const AlertDescriptionCell: FC<IAlertDescriptionCellProps> = ({ alert, metadata }) => {
    const rawDescription = alert.urgencyLevel == UrgencyLevel.Closed ? metadata.closedStateDescription : metadata.openStateDescription;
    const formatter = createDescriptionFormatter(alert, metadata);
    return <DescriptionCell rawDescription={rawDescription} formatter={formatter} />;
};

interface IDescriptionCellProps {
    rawDescription: string;
    formatter?: AlertDescriptionTokenFormatter<JSX.Element, number>;
}

export const DescriptionCell: FC<IDescriptionCellProps> = ({ rawDescription, formatter }) => {
    // the parser doesn't like the colon in the AdditionalData field, so we replace it with an underscore:
    const parserNormalizedDescription = rawDescription.replace("AdditionalData:", "AdditionalData_");
    const descriptionTokens = parse(parserNormalizedDescription);
    const elements: JSX.Element[] = descriptionTokens.map((token, index) => {
        if (token.type === "argument") {
            const tokenFormatter = formatter?.[token.arg as keyof AlertDescriptionTokenFormatter<JSX.Element, number>];
            if (tokenFormatter) {
                const element = tokenFormatter(index);
                if (element) {
                    return element;
                }
            }
        }
        return <span key={index}>{token.ctx.text}</span>;
    });

    return <LightTooltipOnEllipsis
        title={elements}
        customTooltipSx={{ padding: "1.2em", fontSize: 14 }}
    >
        <div className={classes.description}>
            {elements}
        </div>
    </LightTooltipOnEllipsis>;
};

export function createDescriptionFormatter(alert: AlertUnion, metadata: IAlertMetadata): AlertDescriptionTokenFormatter<JSX.Element, number> {
    if (alert.kind === "single-device") {
        return {
            "ComponentNumber": (index) => metadata.componentType === ComponentType.Tracker
                ? <TrackerNameById id={alert.deviceId} />
                : <span key={index}>{alert.deviceId}</span>,
            "AdditionalData_Minutes": (index) => <span key={index}>{alert.additionalInfo && formatMinutesAgo(alert.additionalInfo)}</span>,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            "AdditionalData_Percent": (index) => <span key={index}>{fractionToPercentage(alert.additionalInfo!)}</span>,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            "AdditionalData_SafeSource": (index) => <span key={index}>{humanizeEnumValue(SafeReason[alert.additionalInfo!])}</span>
        };
    }
    if (alert.kind === "aggregated") {
        return {
            "Percent": (index) => <span key={index}>{fractionToPercentage(alert.errorFraction)}</span>,
            "Total": (index) => <span key={index}>{alert.deviceIds.length}</span>
        };
    }
    return {};
}