import { Dayjs } from "dayjs";
import { ChartTypeEnum } from "../../SolarGikLib/Model";
import { IChartSingleLineConfig } from "../data_point/models/TagChartModel";

interface ISiteTagsProps {
  siteId: string;
  category: string;
  uiName: string;
  deviceId: number | null;
}
export const initSiteTagsProps: ISiteTagsProps = {
  siteId: "",
  category: "",
  uiName: "",
  deviceId: null,
};

export interface ITrendConfig {
  title: string;
  rangeTime: [Dayjs, Dayjs];
  samplingInterval: number;
  chartType: ChartTypeEnum;
}

export interface ITrendState {
  [id: string]: {
    trendConfig: ITrendConfig;
    linesConfig: ITrendLine[];
  };
}
export interface ITrendViewProps {
  trendConfig: ITrendConfig;
  linesConfig: ITrendLine[];
}
export interface ITrendLine extends IChartSingleLineConfig {
  site: string;
  tag: string;
  category: string;
  uiName: string;
  deviceId: number | null;
  chartType: ChartTypeEnum;

}
export const colors = [
  "#E3CDFF",
  "#FFC56F",
  "#B2E1AF",
  "#A8D1DE",
  "#D76372",
  "#FFB9C2",
  "#CDFFFF",
  "#FFE99A",
  "#D7E7FF",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
];
export enum TrendsUserEvents {
  ColorChange,
  FillChange,
  DashChange,
  MinChange,
  MaxChange,
  ShowLineChange,
  DeleteTag,
}
export enum InputFieldType {
  Undefined = "undefined",
  RangeTime = "rangeTime",
  SiteId = "siteId",
  UiName = "uiName",
  Category = "category",
  DeviceId = "deviceId",
} 