import { FC, useState } from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  Box,
  Drawer,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import classes from "./NavigationDrawer.module.css";
import { NavConfig } from "./NavigationDrawerModels";
import { drawerStyling } from "../../../common/Mui/MuiStyling";
import { getCssVariable } from "../../../cssVarUtils";

interface INavigationDrawerProps {
  navigationItems: NavConfig[];
  navigationPrefx?: string;
}

const NavigationDrawer: FC<INavigationDrawerProps> = ({ navigationItems, navigationPrefx }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const handleMouseHover = (index: number) => setHoveredIndex(index);
  const handleMouseOut = () => setHoveredIndex(null);
  const activeListItemColor = getCssVariable("--nav-active-list-item-color");
  const inactiveListItemColor = getCssVariable("--nav-list-item-color");
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={drawerStyling}
      >
        <List className={classes["list"]}>
          {navigationItems.map((item, index) => {
            return (
              <NavLink
                to={navigationPrefx ? `${navigationPrefx}${item.pagePath}` : item.pagePath}
                key={item.pagePath}
                style={{ textDecoration: "none" }}
              >
                {({ isActive }) => {
                  const isHovered = hoveredIndex === index;
                  const ItemIcon = item.icon;
                  return (
                    <ListItem
                      className={`${classes["list-item"]} ${isActive ? classes.active : ''} ${isHovered ? classes.hovered : ''}`}
                      key={item.pagePath}
                      onMouseOver={() => handleMouseHover(index)}
                      onMouseOut={handleMouseOut}
                    >
                      <div className={classes["label"]}>
                        {item.pageName}
                      </div>
                      <ListItemIcon>
                        <ItemIcon
                          className={`${classes["icon"]} ${isHovered ? classes['icon-hovered'] : ''}`}
                          backgroundColor={isActive ? inactiveListItemColor : activeListItemColor}
                          strokeColor={isActive ? activeListItemColor : inactiveListItemColor}
                        />
                      </ListItemIcon>
                    </ListItem>
                  );
                }}
              </NavLink>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );
};

export default NavigationDrawer;
