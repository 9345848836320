import axios from "axios";

import { IUserData } from "./UserStore";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getUserData = async (signal: AbortSignal): Promise<IUserData> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetUserInfo;
  const httpResponse = await axios.get<IUserData>(url, { signal });
  return httpResponse.data;
};
