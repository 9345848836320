import { ITagAlias, ITagToMetaDataDictionary } from "../data_point/models/TagsModels";

export type InverterCapacityKw = number;
export type TrackerName = string;
export type DeviceId = number;

export type DevicesMap<T> = { [deviceId: DeviceId]: T };
export type TrackerNamesMap = DevicesMap<TrackerName | null | undefined>;


export interface ISitesMetadataDictionary {
  [siteId: string]: ISiteMetadata;
}
export enum SiteTrackerType {
  GPT = "GPT",
  SPT = "SPT",
}

export enum PowerMeterType {
  None = 0,
  Total = 1,
  Moment = 2
}

export enum WeatherSensorFeatures {
  None = 0,
  WindSpeed = 1 << 0,
  WindDirection = 1 << 1,
  Irradiation = 1 << 2,
  IrradiationInstalledOnTracker = 1 << 3,
  IrradiationFixedTilt = 1 << 4,
  Temperature = 1 << 5,
  TemperatureInstalledOnPanel = 1 << 6,
  Barometric = 1 << 7,
  RelativeHumidity = 1 << 8,
  Snow = 1 << 9,
}

export enum AgriSensorType {
  Unknown = 0,
  CwtSoil = 1,
  CwtPhotoSynth = 2,
  CwtRainGauge = 3,
  HondeGrowthSensor = 4
}

export interface ISiteMetadata {
  siteId: string;
  ianaTimeZoneName: string;
  trackers: DevicesMap<TrackerName | null | undefined>;
  powerMeters: DevicesMap<PowerMeterType>;
  inverters: DevicesMap<InverterCapacityKw>;
  weatherSensors: DevicesMap<WeatherSensorFeatures>;
  agriSensors: DevicesMap<AgriSensorType>;
  trackerType?: SiteTrackerType;
  upsExists: boolean;
  visualInfo: ISiteVisualInfo;
  tags: ITagToMetaDataDictionary;
  tagAliases: { [alias: string]: ITagAlias };
}

export interface ISiteVisualInfo {
  displayName: string;
  isAgriPageVisible: boolean;
  isEnableUserRecordsFeature: boolean;
  isEnableSprinklersFeature: boolean;
  isEnableWeatherFeature: boolean;
  isEnableQueryHistoryForMoreThanOneWeek: boolean;
  isEnableIssuesFeature: boolean;
}
