import { FC, useState, useRef, useEffect } from "react";
import { DatePicker, Space, Button } from "antd";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import classes from "./SgAntdDateRangePicker.module.css";
import { TextEnum } from "../TextStyles";
import { DAYJS_YEAR_TO_DAY_FORMAT } from "../../features/app/DayjsUtils";
const { RangePicker } = DatePicker;

export interface IRangePreset {
    label: string;
    value: [Dayjs, Dayjs];
}
interface IDateTimeRange {
    updateRange: (start: Dayjs, end: Dayjs) => void;
    rangePresets: IRangePreset[];
    maxRange: { value: number, unit: ManipulateType };
    initalStart: Dayjs;
    initialEnd: Dayjs;
    disabledDate: (current: Dayjs, from?: Dayjs) => boolean;
}

const SgDateRangePicker: FC<IDateTimeRange> = ({ updateRange, rangePresets, maxRange, initalStart, initialEnd, disabledDate }) => {
    const [dates, setDates] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([initalStart, initialEnd]);
    const [isFinalDate, setIsFinalDate] = useState<boolean>(false);
    useEffect(() => updateRange(dates[0], dates[1]), [isFinalDate]);

    //antd datepicker has no types for ref
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rangePickerRef = useRef<any>(null);

    const isValidRange = (dates: Dayjs[]) =>
        dates[1].diff(dates[0], maxRange.unit) <= maxRange.value;

    const disabledDateCallback = (date: Dayjs, { from }: { from?: Dayjs }) => {
        if (disabledDate(date, from)) {
            return true;
        }
        if (!from) {
            return false;
        }
        return !isValidRange([from, date]);
    }

    const onRangeChange = (dates: Array<Dayjs | null>) => {
        if (dates == null || dates.length < 1 || dates[0] == null || dates[1] == null) {
            return;
        }
        if (!isValidRange(dates as Dayjs[])) {
            dates[1] = dates[0].add(maxRange.value, maxRange.unit);
        }
        setDates([dates[0], dates[1]]);
    };
    const onOpenChange = (open: boolean) => {
        if (!open) {
            setIsFinalDate(!isFinalDate);
        }
    };
    return (
        <Space direction="vertical" size={12}>
            <RangePicker
                onOpenChange={onOpenChange}
                className={TextEnum.h4}
                ref={rangePickerRef}
                value={dates}
                onCalendarChange={onRangeChange}
                disabledDate={disabledDateCallback}
                format={DAYJS_YEAR_TO_DAY_FORMAT}
                renderExtraFooter={() => (
                    <div className={classes["range-presets"]}>
                        <Space size={30}>
                            {rangePresets.map((preset) => (
                                <Button
                                    key={preset.label}
                                    disabled={!isValidRange(preset.value)}
                                    onClick={() => {
                                        onRangeChange(preset.value);
                                        rangePickerRef.current?.blur();
                                    }}
                                >
                                    {preset.label}
                                </Button>
                            ))}
                        </Space>
                    </div>
                )}
            />
        </Space>
    );
};

export default SgDateRangePicker;
