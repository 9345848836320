import { parse } from '@messageformat/parser';
import { formatDistanceToNow, subMinutes } from "date-fns";
import getIcons from "../../SolarGikLib/icons/Icons";
import { ErrorIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { TrackerNamesMap } from "../sites/SiteModels";
import { ComponentType, IAlertMetadata, SafeReason, UrgencyLevel } from "./DTOs";
import { humanizeEnumValue } from '../../common/EnumUtils';
import { tryGetTrackerName } from '../trackers/TrackerName';
import { AlertUnion } from './Models';
const SeverityHighIcon = getIcons(ErrorIcons.MultisiteHighSeverityError, IconCategory.Error);
const SeverityMildIcon = getIcons(ErrorIcons.MultisiteMildSeverityError, IconCategory.Error);
const SeverityLowIcon = getIcons(ErrorIcons.MultisiteLowSeverityError, IconCategory.Error);
const SeverityUndefinedIcon = getIcons(ErrorIcons.MultisiteUndefinedError, IconCategory.Error);

export const mapSverityToIcons = (value: UrgencyLevel) => {
    switch (value) {
        case UrgencyLevel.High:
            return SeverityHighIcon;
        case UrgencyLevel.Medium:
            return SeverityMildIcon;
        case UrgencyLevel.Low:
            return SeverityLowIcon;
        default:
            return SeverityUndefinedIcon;
    }
};

export const convertEnumToIconName = (enumValue: UrgencyLevel): string => {
    switch (enumValue) {
        case UrgencyLevel.High:
            return ErrorIcons.HighSeverityError;
        case UrgencyLevel.Medium:
            return ErrorIcons.MildSeverityError;
        case UrgencyLevel.Low:
            return ErrorIcons.LowSeverityError;
        default:
            return ErrorIcons.UndefinedSeverityError;
    }
};

type AlertDescriptionToken =
    "ComponentNumber" |
    "AdditionalData_Minutes" |
    "AdditionalData_Percent" |
    "AdditionalData_SafeSource" |
    "Percent" |
    "Total";


export type AlertDescriptionTokenFormatter<T, TArg = void> = {
    [token in AlertDescriptionToken]?: (arg: TArg) => T;
}

export const formatMinutesAgo = (minutes: number) =>
    formatDistanceToNow(subMinutes(new Date(), minutes), { addSuffix: false });

export function createAlertDescriptionTokenFormatter(alert: AlertUnion, metadata: IAlertMetadata, trackerNamesMap: TrackerNamesMap): AlertDescriptionTokenFormatter<string | number> {
    if (alert.kind === "single-device") {
        return {
            "ComponentNumber": () => metadata.componentType === ComponentType.Tracker
                ? tryGetTrackerName(alert.deviceId, trackerNamesMap)
                : alert.deviceId,
            "AdditionalData_Minutes": () => alert.additionalInfo !== undefined ? formatMinutesAgo(alert.additionalInfo) : "",
            "AdditionalData_Percent": () => alert.additionalInfo !== undefined ? fractionToPercentage(alert.additionalInfo) : "",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            "AdditionalData_SafeSource": () => alert.additionalInfo !== undefined ? humanizeEnumValue(SafeReason[alert.additionalInfo])! : ""
        }
    }
    if (alert.kind === "aggregated") {
        return {
            "Percent": () => fractionToPercentage(alert.errorFraction),
            "Total": () => alert.deviceIds.length
        }
    }
    return {};
}

export function formatAlertDescription(alert: AlertUnion, metadata: IAlertMetadata, trackerNamesMap: TrackerNamesMap): string {
    const formatter = createAlertDescriptionTokenFormatter(alert, metadata, trackerNamesMap);
    const descriptionRaw = alert.urgencyLevel == UrgencyLevel.Closed ? metadata.closedStateDescription : metadata.openStateDescription;
    return applyAlertDescriptionTextFormatter(descriptionRaw, formatter);
}

export function applyAlertDescriptionTextFormatter(rawDescription: string, formatter: AlertDescriptionTokenFormatter<string | number>): string {
    // the parser doesn't like the colon in the AdditionalData field, so we replace it with an underscore:
    const parserNormalizedDescription = rawDescription.replace("AdditionalData:", "AdditionalData_");
    const descriptionTokens = parse(parserNormalizedDescription);
    return descriptionTokens.map((token) => {
        if (token.type === "argument") {
            const tokenFormatter = formatter[token.arg as AlertDescriptionToken];
            if (tokenFormatter) {
                return tokenFormatter();
            }
        }
        return token.ctx.text;
    }).join("");
}

export const fractionToPercentage = (fraction: number) =>
    Math.round(fraction * 100);