import { FC } from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { SystemStyleObject } from "@mui/system";

interface DarkTooltipProps extends TooltipProps {
  sx?: SystemStyleObject;
}

const DarkTooltip: FC<DarkTooltipProps> = ({ children, sx, ...props }) => {
  return (
    <Tooltip
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            whiteSpace: "pre-line",
            textAlign: "center",
            py: "10px",
            px: "25px",
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: "var(--tooltip-border-radius)",
            fontFamily: "var(--font-family)",
            fontWeight: "400",
            boxShadow: "0 0 25px rgba(0,0,0,0.7)",
            backgroundColor: "var(--grey-tooltip)",
            ...(sx || {}),
          },
          "@media screen and (max-width: 1600px)": {
            "& .MuiTooltip-tooltip": {
              fontSize: 14,
              py: "8px",
              px: "20px",
            },
          },
          ...(props.PopperProps?.sx || {}),
        },
      }}
      arrow
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default DarkTooltip;
