import { PageNames } from "../../../pages/PageNames";
import { UserAccessType } from "../../user/UserStore";
import { NavConfig } from "../NavigationDrawer/NavigationDrawerModels";
import {
  AgriIcon, ConfigurationIcon, DashboardIcon,
  HistoryIcon, IssuesIcon, SprinklersIcon,
  TrackersIcon, UserRecordsIcon, WeatherIcon
} from "../../../SolarGikLib/icons/common/navigation/NavIcons"

export const NavItemsConfig: NavConfig[] = [
  {
    pageName: "Dashboard",
    pagePath: "/" + PageNames.Dashboard,
    icon: DashboardIcon,
    visiblityFilter: (userAccessType) => userAccessType >= UserAccessType.ViewDashboard
  },
  {
    pageName: "History",
    pagePath: "/" + PageNames.History,
    icon: HistoryIcon,
    visiblityFilter: (userAccessType) => userAccessType >= UserAccessType.Viewer

  },
  {
    pageName: "Trackers",
    pagePath: "/" + PageNames.Trackers,
    icon: TrackersIcon,
    visiblityFilter: (userAccessType) => userAccessType >= UserAccessType.Viewer
  },
  {
    pageName: "User Records",
    pagePath: "/" + PageNames.UserRecords,
    icon: UserRecordsIcon,
    visiblityFilter: (userAccessType, visualInfo) =>
      userAccessType >= UserAccessType.Viewer && visualInfo.isEnableUserRecordsFeature
  },
  {
    pageName: "Agriculture",
    pagePath: "/" + PageNames.Agriculture,
    icon: AgriIcon,
    visiblityFilter: (userAccessType, visualInfo) =>
      userAccessType >= UserAccessType.Viewer && visualInfo.isAgriPageVisible
  },
  {
    pageName: "Alerts",
    pagePath: "/" + PageNames.Alerts,
    icon: IssuesIcon,
    visiblityFilter: (userAccessType, visualInfo) =>
      userAccessType >= UserAccessType.Viewer && visualInfo.isEnableIssuesFeature
  },
  {
    pageName: "Sprinklers",
    pagePath: "/" + PageNames.Sprinklers,
    icon: SprinklersIcon,
    visiblityFilter: (userAccessType, visualInfo) =>
      userAccessType >= UserAccessType.Viewer && visualInfo.isEnableSprinklersFeature
  },
  {
    pageName: "Weather",
    pagePath: "/" + PageNames.Weather,
    icon: WeatherIcon,
    visiblityFilter: (userAccessType, visualInfo) =>
      userAccessType >= UserAccessType.Engineer && visualInfo.isEnableWeatherFeature
  },
  {
    pageName: "Config",
    pagePath: "/" + PageNames.Configuration,
    icon: ConfigurationIcon,
    visiblityFilter: (userAccessType) => userAccessType >= UserAccessType.Engineer
  },
];
