export const SPLIT_NAME_DELIMITER = ":";
const METADATA_NUMBER_ID_AS_MASTER = 0;
const DEVICE_NUMBER_PLACEHOLDER = "DEVICE#";
export const METADATA_NUMBER_ID_AS_AGG = 32767;

export enum TagUiCategory {
  Inverters = "Inverters",
  Trackers = "Trackers",
  Weather = "Weather",
  System = "System",
  Energy = "Energy",
  PowerMeter = "PowerMeter",
  Engineer = "Engineer",
  Agriculture = "Agriculture",
}

export class TagUiModel {
  constructor(
    public siteName: string,
    public tagUiCategory: TagUiCategory,
    public tagUiName: string,
    public deviceNumber: number | null,
    public isEngineerTag: boolean,
    public tagId: string
  ) { }
  displayName() {
    return (
      this.tagUiCategory +
      ":" +
      this.tagUiName +
      (this.deviceNumber ? ` ${this.deviceNumber}` : "")
    );
  }
}

export class TagUiModelMetadata {
  constructor(
    public tagUiCategory: TagUiCategory,
    public tagUiName: string,
    public isEngineerTag: boolean,
    public hasDeviceNumber: boolean
  ) { }
}

const extractTagNameMetadataDetails = (tagName: string) => {
  const tagParts = tagName.split(SPLIT_NAME_DELIMITER);
  if (tagParts.length !== 3 && tagParts.length !== 2) {
    throw new Error(`Invalid tag name: ${tagName}`);
  }

  if (tagParts.length == 2) {
    return {
      tagPrefixNoDevice: tagParts[0] + SPLIT_NAME_DELIMITER + tagParts[1],
      masterNumberComponent: null,
      deviceNumber: null
    };
  }

  const number = parseInt(tagParts[2]);
  if (isNaN(number)) {
    return {
      tagPrefixNoDevice: tagName,
      masterNumberComponent: null,
      deviceNumber: null
    };
  }
  const masterNumberComponent = number === METADATA_NUMBER_ID_AS_AGG ||
    number === METADATA_NUMBER_ID_AS_MASTER
    ? number
    : null
  return {
    tagPrefixNoDevice: tagParts[0] + SPLIT_NAME_DELIMITER + tagParts[1],
    masterNumberComponent,
    deviceNumber: masterNumberComponent ? null : number
  };
}

const tagNameToTagUiMapMetadata = initTagsMetadataMap();


export function generateTagUiModels(
  siteName: string,
  tagNames: string[],
  isAgriSite: boolean
): TagUiModel[] {
  const tagUiModels: TagUiModel[] = [];
  for (const tagName of tagNames) {
    const tagNameParts = extractTagNameMetadataDetails(tagName);
    const tagNameLookupKey = tagNameParts.deviceNumber !== null
      ? tagNameParts.tagPrefixNoDevice + SPLIT_NAME_DELIMITER + DEVICE_NUMBER_PLACEHOLDER
      : tagName;
    const tagUiModelMetadata = tagNameToTagUiMapMetadata.get(tagNameLookupKey);
    if (tagUiModelMetadata?.tagUiCategory == TagUiCategory.Agriculture && !isAgriSite) {
      continue;
    }
    const isUnknownTag = !tagUiModelMetadata;
    const newModel = new TagUiModel(
      siteName,
      isUnknownTag ? TagUiCategory.Engineer : tagUiModelMetadata.tagUiCategory,
      isUnknownTag ? tagNameParts.tagPrefixNoDevice : tagUiModelMetadata.tagUiName,
      tagNameParts.deviceNumber,
      isUnknownTag || tagUiModelMetadata.isEngineerTag,
      tagName
    );
    tagUiModels.push(newModel);
    console.debug(
      `Unknown tag name - engineer tag: ${tagName} ${newModel} ${tagNameParts}`
    );
  }
  return tagUiModels;
}

function initTagsMetadataMap(): Map<string, TagUiModelMetadata> {
  const tagUiMap = new Map<string, TagUiModelMetadata>();
  // Agri tags
  const agriTags = [
    { name: "SoilHumidity", displayName: "Soil Humidity" },
    { name: "SoilTemperature", displayName: "Soil Temperature" },
    { name: "SoilPH", displayName: "Soil PH" },
    { name: "SoilConductivity", displayName: "Soil Conductivity" },
    { name: "SoilNitrogen", displayName: "Soil Nitrogen" },
    { name: "SoilPotassium", displayName: "Soil Potassium" },
    { name: "SoilPhosphorus", displayName: "Soil Phosphorus" },
    { name: "PlantPhotosyntheticallyActiveRadiationPpfd", displayName: "PAR" },
    { name: "RainFall", displayName: "Rain fall" },
    { name: "StemGrowth", displayName: "Stem Growth" },
  ]
  agriTags.forEach(({ name, displayName }) => {
    tagUiMap.set(`Agri:${name}:${DEVICE_NUMBER_PLACEHOLDER}`,
      new TagUiModelMetadata(TagUiCategory.Agriculture, `${displayName} per device`, false, true)
    );
    tagUiMap.set(`Agri:${name}:${METADATA_NUMBER_ID_AS_AGG}`,
      new TagUiModelMetadata(TagUiCategory.Agriculture, displayName, false, false,)
    );
  });
  //Tracker tags
  tagUiMap.set("Trackers:StateCount:Tracking",
    new TagUiModelMetadata(TagUiCategory.Trackers, "All In Tracking", false, false)
  );
  tagUiMap.set("Trackers:StateCount:Manual",
    new TagUiModelMetadata(TagUiCategory.Trackers, "All In Maintenance", false, false));
  tagUiMap.set("Calculated:McsState:FieldState",
    new TagUiModelMetadata(TagUiCategory.System, "Field State", false, false));
  tagUiMap.set(`TrackerStatus:CurrentState:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Trackers, "State Per Device", false, true));
  tagUiMap.set(`TrackerStatus:ShadingRatio:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Trackers, "Shading Per Device", false, true));
  tagUiMap.set(`TrackerStatus:CurrentElevation:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Trackers, "Elevation Per Device", false, true));
  tagUiMap.set(`TrackerStatus:ErrorType:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Trackers, "Issues Per Device", false, true,));
  // Power meter tags
  tagUiMap.set(`PowerMeter:ProductionDelta:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.PowerMeter, "Production Delta", true, true));
  tagUiMap.set(`PowerMeter:ProductionTotal:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.PowerMeter, "Production Per Device", false, true));
  tagUiMap.set(`PowerMeter:ErrorType:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.PowerMeter, "Issues Per Device", false, true,));
  // System tags
  tagUiMap.set("PowerMeter:AggrStatus",
    new TagUiModelMetadata(TagUiCategory.System, "Status", true, false));
  tagUiMap.set(`McsState:UpsEmergencyStatus:${METADATA_NUMBER_ID_AS_MASTER}`,
    new TagUiModelMetadata(TagUiCategory.System, "Emergency Ups", false, false));
  tagUiMap.set(`McsState:EngineerEmergencyStatus:${METADATA_NUMBER_ID_AS_MASTER}`,
    new TagUiModelMetadata(TagUiCategory.System, "Emergency SG Engineer", false, false));
  tagUiMap.set(`McsState:CloudEmergencyStatus:${METADATA_NUMBER_ID_AS_MASTER}`,
    new TagUiModelMetadata(TagUiCategory.System, "Emergency Soma User", false, false));
  // Energy tags
  tagUiMap.set("Calculated:PotentialPowerProduction",
    new TagUiModelMetadata(TagUiCategory.Energy, "Potential", false, false));
  tagUiMap.set("Calculated:ContractPowerProduction",
    new TagUiModelMetadata(TagUiCategory.Energy, "Contract", false, false));
  tagUiMap.set(`McsState:State:${METADATA_NUMBER_ID_AS_MASTER}`,
    new TagUiModelMetadata(TagUiCategory.Energy, "State", true, false));
  tagUiMap.set("Calculated:TotalDaily:Sp",
    new TagUiModelMetadata(TagUiCategory.Energy, "Specific Prod' Daily", false, false));
  tagUiMap.set("Calculated:Current:PerformanceIndex",
    new TagUiModelMetadata(TagUiCategory.Energy, "Perf' Index", false, false));
  tagUiMap.set(`Inverter:AcPower:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Energy, "Ac Power", false, false));
  tagUiMap.set(`Inverter:DcPower${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Energy, "Dc Power", false, false));
  tagUiMap.set("Calculated:TotalDaily:AcPower",
    new TagUiModelMetadata(TagUiCategory.Energy, "Ac Power Daily", false, false));
  tagUiMap.set("Calculated:TotalDaily:DcPower",
    new TagUiModelMetadata(TagUiCategory.Energy, "Dc Power Daily", false, false));
  // Inverters tags
  tagUiMap.set(`Inverter:AcPower:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Inverters, "AC Per Device", false, true));
  tagUiMap.set(`Inverter:ErrorType:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Inverters, "Issues Per Device", false, true));
  tagUiMap.set(`Inverter:DcPower:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Inverters, "DC Per Device", false, true));
  tagUiMap.set("Inverter:AggrStatus",
    new TagUiModelMetadata(TagUiCategory.Inverters, "Status", false, false));
  tagUiMap.set("Inverter:ErrorType:ErrorCount",
    new TagUiModelMetadata(TagUiCategory.Inverters, "All Severe Issues", false, false));
  tagUiMap.set("Inverter:ErrorType:WarnCount",
    new TagUiModelMetadata(TagUiCategory.Inverters, "All Mild Issues", false, false));
  // Weather tags
  tagUiMap.set(`Weather:ErrorType:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Issues Per Device", false, true));
  tagUiMap.set(`Weather:WindSpeed:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Wind Speed", false, false));
  tagUiMap.set(`Weather:WindDirection:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Wind Direction", false, false));
  tagUiMap.set(`Weather:BackOfModuleTemp:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Temperature", false, false));
  tagUiMap.set(`Weather:RelativeHumidity:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Humidity", false, false));
  tagUiMap.set(`Weather:BarometricPressure:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Barometric presume", false, false));
  tagUiMap.set("Weather:AggrStatus",
    new TagUiModelMetadata(TagUiCategory.Weather, "Status", true, false));
  tagUiMap.set(`Weather:WindSpeed:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Wind Speed Per Device", false, true));
  tagUiMap.set("Weather:ErrorType:ErrorCount",
    new TagUiModelMetadata(TagUiCategory.Weather, "All Severe Issues", false, false));
  tagUiMap.set("Weather:ErrorType:WarnCount",
    new TagUiModelMetadata(TagUiCategory.Weather, "All Mild Issues", false, false));
  tagUiMap.set(`Weather:EstimatedDni:${METADATA_NUMBER_ID_AS_MASTER}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "DNI", false, false));
  tagUiMap.set(`Weather:GlobalIrradiance:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "GHI", false, false));
  tagUiMap.set(`Weather:GlobalIrradiance:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Irradiance Per Device ", false, true));
  tagUiMap.set(`Weather:RelativeHumidity:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Humidity Per Device", false, true));
  tagUiMap.set(`Weather:AmbientAirTemp:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Air Temperature", false, false));
  tagUiMap.set(`Weather:AmbientAirTemp:${DEVICE_NUMBER_PLACEHOLDER}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "Air Temperature Per Device", false, true));
  tagUiMap.set(`Weather:PlaneOfArrayIrradiance:${METADATA_NUMBER_ID_AS_AGG}`,
    new TagUiModelMetadata(TagUiCategory.Weather, "POA", true, false));

  return tagUiMap;
}
