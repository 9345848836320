import { FC } from "react";

import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import classes from "./TimeQueryUI.module.css";
import { InputFieldType } from "./TrendsModel";
import getIcons from "../../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import { DAYJS_HOUR_TO_MINUTE_FORMAT, DAYJS_YEAR_TO_MINUTE_FORMAT } from "../app/DayjsUtils";

const { RangePicker } = DatePicker;

interface TimeQueryUIProps {
  initDateRangeValues: [Dayjs, Dayjs];
  setRangeTime: (rangeTime: [Dayjs, Dayjs]) => void;
  focusedField: InputFieldType;
}
const TimeQueryUI: FC<TimeQueryUIProps> = ({
  initDateRangeValues,
  setRangeTime,
  focusedField,
}) => {
  const isFocused = focusedField === InputFieldType.RangeTime;
  const cssClassName = isFocused
    ? `${TextEnum.h1} ${classes["option"]} ${classes["focused"]}`
    : `${TextEnum.h1} ${classes["option"]}`;

  const rangeTimeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates !== null && dates[0] !== null && dates[1] !== null) {
      setRangeTime([dates[0].startOf("second").utc(true), dates[1].startOf("second").utc(true)]);
    }
  };
  const startDate = initDateRangeValues[0].format(DAYJS_YEAR_TO_MINUTE_FORMAT);
  const endDate = initDateRangeValues[1].format(DAYJS_YEAR_TO_MINUTE_FORMAT);
  const CalenderIcon = getIcons(FieldIcons.Calender, IconCategory.Fields);
  return (
    <div className={classes["item-wrapper"]}>
      <RangePicker
        size={"small"}
        separator={
          <div className={classes["separator-wrapper"]}>
            <span className={classes["separator"]}>-</span>
          </div>
        }
        suffixIcon={<CalenderIcon />}
        placeholder={[startDate, endDate]}
        className={cssClassName}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getPopupContainer={(trigger: any /*External parameter*/) => trigger}
        showTime={{
          hideDisabledOptions: true,
          defaultValue: [
            dayjs("00:00", DAYJS_HOUR_TO_MINUTE_FORMAT),
            dayjs("00:00", DAYJS_HOUR_TO_MINUTE_FORMAT),
          ],
        }}
        format={DAYJS_YEAR_TO_MINUTE_FORMAT}
        onChange={rangeTimeChange}
        inputReadOnly
      />
    </div>
  );
};
export default TimeQueryUI;
