import { keyframes } from "@mui/material";

const disabled = "var(--disable-color)";
const primaryColor = "var(--blue-color-primary)";
const border = `1px solid ${primaryColor}`;
const hoverColor = "var(--button-hover-color)";
const whiteText = "var(--button-text-color)";
const defaultMargin = "17px 15px";
const defaultPadding = "13px 64px";
const defaultSmallScreenMargin = "10px 10px";
const defaultNarrowMargin = "0px 15px";
const defaultNarrowPadding = "6px 15px";
const defaultFontSize = "var(--font-size-button)";
const defaultSmallScreenFontSize = "12px";
const defaultFontWeight = "300";
export const outlinedWhiteButton = {
  fontSize: defaultFontSize,
  margin: defaultMargin,
  padding: defaultPadding,
  backgroundColor: "var(--transparent)",
  border: `1px solid ${primaryColor}`,
  color: primaryColor,
  "&:hover": {
    backgroundColor: hoverColor,
  },
  "&:disabled": {
    border: `1px solid ${disabled}`,
    color: disabled,
  },
  "@media screen and (max-width: 1600px)": {
    padding: defaultNarrowPadding,
    margin: defaultSmallScreenMargin,
    fontSize: defaultSmallScreenFontSize
  },
};
export const narrowOutlinedWhiteButton = {
  width: "100%",
  fontSize: defaultFontSize,
  margin: defaultNarrowMargin,
  padding: defaultNarrowPadding,
  backgroundColor: "var(--transparent)",
  border: border,
  color: primaryColor,
  "&:hover": {
    backgroundColor: hoverColor,
  },
  "&:disabled": {
    border: "1px solid #C2C6D4",
    color: disabled,
  },
  "@media screen and (max-width: 1600px)": {
    margin: defaultSmallScreenMargin,
    fontSize: defaultSmallScreenFontSize
  },
};
export const narrowBlueButton = {
  width: "100%",
  fontSize: defaultFontSize,
  fontWeight: defaultFontWeight,
  backgroundColor: "var(--blue-button-background-color)",
  color: whiteText,
  "&:hover": {
    backgroundColor: "var(--filled-button-on-hover-color)",
  },
  "&:disabled": {
    color: whiteText,
    backgroundColor: disabled,
  },
  padding: defaultNarrowPadding,
  "@media screen and (max-width: 1600px)": {
    margin: defaultSmallScreenMargin,
    fontSize: defaultSmallScreenFontSize
  }
};
export const filledBlueButton = {
  fontSize: defaultFontSize,
  margin: defaultMargin,
  padding: defaultPadding,
  backgroundColor: "var(--blue-button-background-color)",
  "&:hover": {
    backgroundColor: "var(--filled-button-on-hover-color)",
  },
  "&:disabled": {
    color: whiteText,
    backgroundColor: disabled,
  },
  "&&": {
    color: whiteText,
    "&:disabled": {
      color: whiteText,
      backgroundColor: disabled,
    },
  },
  "@media screen and (max-width: 1600px)": {
    padding: defaultNarrowPadding,
    margin: defaultSmallScreenMargin,
    fontSize: defaultSmallScreenFontSize
  },
};
export const iconButtonStyle = {
  fontSize: defaultFontSize,
  margin: "0px 0px",
  padding: "0px 0px",
  backgroundColor: "var(--transparent-color)",
  border: "none",
  "&&": {
    color: whiteText,
    "&:disabled": {
      color: whiteText,
      backgroundColor: "var(--transparent-color)",
      padding: "0px 0px",
      margin: "0px 0px",
    },
  },
  "&:hover": {
    backgroundColor: "var(--transparent-color)",
  },
  "&:disabled": {
    color: whiteText,
    backgroundColor: "var(--transparent-color)",
  },
  "& .MuiTouchRippleRoot": {
    display: "none",
  },
  "& .MuiButtonEndIcon": {
    marginRight: "0px",
    marginLeft: "0px",
  },
};

const colorChange = keyframes` 
    0% {
      background-color: var(--animate-button-start-color);
    }
    100% {
      background-color: var(--animate-button-end-color);
    }
  
`;
export const animatedButton = {
  color: whiteText,
  animation: `${colorChange} 1s ease-in-out infinite alternate`,
  boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.16)",
  padding: "10px 30px",
};
