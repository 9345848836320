import { useSelector } from "react-redux";
import { TagTimeValidity } from "./models/TagsModels";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { RootState } from "../app/Store";
import { selectSiteTag, selectSiteTagDataStr } from "../app/store/MultisiteTagsStore";
import { invalidTagMetaData } from "../app/TagsMetadataUtils";

const DataPointInfo = (
  tagName: string,
  siteId: string,
  titleOverride?: string,
  valueToStringFuncOverride?: (value: number) => string
) => {
  const tag = useSelector((state: RootState) => selectSiteTag(state, siteId, tagName));
  const tagValuesStr = useSelector((state: RootState) => selectSiteTagDataStr(state, siteId, tagName));
  const tagMetaData = useSelector((state: RootState) =>
    state.multiSitesMetadata.sites[siteId]?.tags[tagName] ?? invalidTagMetaData
  );

  let valueInString: string;
  if (tag.timeValidity == TagTimeValidity.Invalid) {
    valueInString = NO_VALUE_PLACEHOLDER;
  } else if (valueToStringFuncOverride) {
    valueInString = valueToStringFuncOverride(tag.value);
  } else if (tagMetaData.toStringConverter) {
    valueInString = tagMetaData.toStringConverter(tag.value);
  } else {
    valueInString = tagValuesStr.value;
  }
  const title = titleOverride ?? tagMetaData.displayName;
  const unit = tagMetaData.unitName;
  const valueExist = !!tag.valueExist;
  const tagTimeValidityStatus = tag.timeValidity;

  return {
    title,
    valueInString,
    unit,
    valueExist,
    tagTimeValidityStatus,
  };
};
export default DataPointInfo;
