import { FC } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  Chart,
  Filler,
  Legend,
  BarElement,
  ChartOptions,
  TooltipItem,
  ChartData,
} from "chart.js";
import annotationPlugin, { AnnotationOptions } from "chartjs-plugin-annotation";
import { Bar, Line } from "react-chartjs-2";
import { nanoid } from "@reduxjs/toolkit";
import { TinyColor } from '@ctrl/tinycolor'
import { Dayjs } from "dayjs";
import { CHART_COLORS, COLORS } from "./Colors";
import { ChartTypeEnum } from "./Model";
import CHART_STYLE_CONFIG from "../features/charts_ui/CommonChartConfig";
import {
  BarDataset,
  IChartSingleLineConfig,
  ISolarGikStyledChartProps,
  LineDataset,
} from "../features/data_point/models/TagChartModel";
import { getConverterFunction } from "../features/app/TagsToEnumTable";
import { ITrendLine } from "../features/multisite_trends/TrendsModel";
import { ITimeRange } from "../features/data_point/charts/ChartModel";
import {
  DAYJS_HOUR_TO_MINUTE_FORMAT,
  DAYJS_MONTH_TO_DAY_FORMAT,
  DAYJS_MONTH_TO_MINUTE_FORMAT
} from "../features/app/DayjsUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  BarElement,
  TimeSeriesScale,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  Filler
);

const tooltipBgColor = new TinyColor(CHART_COLORS.TOOLTIP_BACKGROUND).toRgb();

Chart.register({
  id: nanoid(), //typescript crashes without id
  afterDatasetsDraw: function (chart: Chart) {
    const activeElements = chart?.tooltip?.getActiveElements();
    if (!activeElements || activeElements.length === 0) {
      return;
    }
    const activePoint = activeElements[0];
    const ctx = chart.ctx;
    const x = activePoint.element.x;
    const bottomY = chart.chartArea.bottom;
    const topY = chart.chartArea.top;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, bottomY);
    ctx.lineTo(x, topY);
    ctx.lineWidth = 1;
    ctx.strokeStyle = CHART_COLORS.CHART_VERTICAL_LINE;
    ctx.setLineDash([2, 3]);
    ctx.stroke();
    ctx.restore();
  },
  beforeTooltipDraw(chart, { tooltip }) {
    const ctx = chart.ctx;
    if (tooltip.opacity === 0) {
      return false;
    }
    ctx.save();
    ctx.beginPath();
    const shadowColorOpacity = 0.5 * tooltip.opacity;
    ctx.shadowColor = `rgba(0,0,0,${shadowColorOpacity})`;
    ctx.shadowBlur = 5;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.roundRect(tooltip.x, tooltip.y, tooltip.width, tooltip.height, 30);
    const { r, g, b } = tooltipBgColor;
    ctx.fillStyle = `rgba(${r},${g},${b},${tooltip.opacity})`;
    ctx.fill();
    ctx.restore();
  },
});

const splitTime = (range: ITimeRange, intervalInSeconds: number) => {
  const labels: string[] = [];
  const dates: Dayjs[] = [];
  const differenceInDays = range.end.diff(range.start, "days");
  let pointer = range.start;
  while (pointer <= range.end) {
    dates.push(pointer);
    labels.push(formatForTooltip(pointer, differenceInDays));
    pointer = pointer.add(intervalInSeconds, "seconds");
  }
  return { labels, dates };
};

const annotations: AnnotationOptions[] = [];

const yAxisGridConfig = {
  display: true,
  color: CHART_COLORS.GRID_GREY,
  lineWidth: 0.5,
};
function defaultTooltipLabelCallback(tooltipItem: TooltipItem<"bar" | "line">) {
  if (tooltipItem.parsed.y == null) {
    return "";
  }
  const tag = (tooltipItem.dataset as LineDataset | BarDataset).tag ?? "";
  const func = getConverterFunction(tag);
  const val = func(tooltipItem.parsed.y);
  return `${tooltipItem.dataset.label}: ${val}`;
}

const createOptions = (
  linesConfig: IChartSingleLineConfig[],
  rangeTime: ITimeRange,
  labelsInDate: Dayjs[],
  chartTitle?: string,
  overrideShowLegend?: boolean | undefined,
  isShowChartTitle?: boolean,
  toolTipOverrideCallback?: (
    datasetIndex: number,
    datasetLabel?: string
  ) => string
): ChartOptions<"line" | "bar"> => {
  const yAxis: ChartOptions<"line" | "bar">["scales"] = {};
  const combinedYAxisLines = linesConfig.filter((line) => line.isCombinedYAxis);
  if (combinedYAxisLines.length > 0) {
    const maxYaxis = Math.max(
      ...combinedYAxisLines.map((line) => line.yAxisRangeMax ?? 0)
    );
    const minYaxis = Math.min(
      ...combinedYAxisLines.map((line) => line.yAxisRangeMin ?? 0)
    );
    yAxis["defaultYAxis"] = {
      min: minYaxis,
      max: maxYaxis,
      type: "linear",
      position: "left",
      grid: yAxisGridConfig,
      ticks: {
        color: COLORS.DARK_BLUE_PRIMARY,
        font: {
          size: CHART_STYLE_CONFIG.yTicksFontSize,
        },
        count: 5,
        precision: 0,
      },
    };
  }
  linesConfig.forEach((line) => {
    try {
      if (!line.isCombinedYAxis) {
        yAxis[line.displayName] = {
          min: line.yAxisRangeMin,
          max: line.yAxisRangeMax,
          type: "linear",
          display: line.isShowLine,
          position: "right",
          grid: yAxisGridConfig,
          ticks: {
            color: line.color,
            font: {
              size: CHART_STYLE_CONFIG.yTicksFontSize,
            },
          },
        };
      }
    } catch (error) {
      console.error(error);
    }
    if (line.isHorizontalLine) {
      annotations.push({
        drawTime: "afterDatasetsDraw",
        type: "line",
        yMin: line.dashedLineValue ?? 0,
        yMax: line.dashedLineValue ?? 0,
        display: true,
        borderDash: [5, 15],
        yScaleID: line.displayName ? line.displayName : "",
        borderColor: COLORS.DARK_BLUE_PRIMARY,
        borderWidth: 2,
      });
    }
  });

  const visibleLabelIndices = getVisibleLabelIndices(labelsInDate);
  const differenceInDays = rangeTime.end.diff(rangeTime.start, "days");
  const defaultoptions: ChartOptions<"line" | "bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: "index",
      axis: "x",
    },
    scales: {
      x: {
        ticks: {
          callback: function (tickValue: number | string) {
            if (visibleLabelIndices.has(tickValue as number)) {
              return formatForTickLabel(labelsInDate[tickValue as number], differenceInDays);
            }
          },
          maxRotation: 0,
          minRotation: 0,
          color: CHART_COLORS.TICKS,
          font: {
            family: CHART_STYLE_CONFIG.fontFamily,
            size: CHART_STYLE_CONFIG.xTicksFontSize,
          },
        },
        grid: {
          display: true,
          color: CHART_COLORS.X_AXIS_GRID,
          lineWidth: 0.5,
        },
      },
      ...yAxis,
    },
    plugins: {
      tooltip: {
        caretSize: 10,
        cornerRadius: 30,
        bodySpacing: 10,
        mode: "index",
        usePointStyle: true,
        boxWidth: 10,
        boxPadding: 10,
        padding: CHART_STYLE_CONFIG.tooltipPadding,
        intersect: false,
        callbacks: {
          label: (tooltipItem: TooltipItem<"bar" | "line">) =>
            toolTipOverrideCallback != null
              ? toolTipOverrideCallback(
                tooltipItem.datasetIndex,
                tooltipItem.dataset?.label
              )
              : defaultTooltipLabelCallback(tooltipItem),
        },
        titleFont: {
          family: CHART_STYLE_CONFIG.fontFamily,
          size: CHART_STYLE_CONFIG.tooltipTitleFontSize,
        },
        titleColor: COLORS.DARK_BLUE_PRIMARY,
        bodyFont: {
          family: CHART_STYLE_CONFIG.fontFamily,
          size: CHART_STYLE_CONFIG.tooltipBodyFontSize,
        },
        bodyColor: COLORS.DARK_BLUE_PRIMARY,
        backgroundColor: CHART_COLORS.TOOLTIP_BACKGROUND,
      },
      annotation: {
        annotations,
      },
      legend: {
        display: overrideShowLegend == true ? false : true,
        position: "top",
        labels: {
          boxWidth: CHART_STYLE_CONFIG.legendBoxWidth,
          boxHeight: CHART_STYLE_CONFIG.legendBoxHeight,
          usePointStyle: true,
          color: COLORS.DARK_BLUE_PRIMARY,
          font: {
            size: CHART_STYLE_CONFIG.legendFontSize,
            family: CHART_STYLE_CONFIG.fontFamily
          },
          generateLabels: (chart: Chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            return originalLabels.map((label) => {
              return {
                ...label,
                text: ` ${label.text} `,
              };
            });
          },
        },
      },
      title: {
        display: !!chartTitle && !!isShowChartTitle,
        text: chartTitle,
        font: {
          size: CHART_STYLE_CONFIG.titleFontSize,
          family: CHART_STYLE_CONFIG.fontFamily,
        },
        color: COLORS.DARK_BLUE_PRIMARY,
        padding: 20,
      },
    },
  };
  return defaultoptions;
};

const SolarGikStyledChart: FC<ISolarGikStyledChartProps> = ({
  chartConfig,
  linesConfig,
  tooltipOverrideCallback,
}) => {
  const internalChart: ChartData<"line" | "bar"> = {
    labels: [],
    datasets: [],
  };
  const lineNames = linesConfig.map((line) => line.displayName);
  const intervalInSeconds = chartConfig.samplingIntervalInSeconds;
  const { chartData, dateLabels } = createLinesConfiguration(
    lineNames,
    chartConfig.timeRange,
    linesConfig,
    intervalInSeconds,
  );
  const overrideShowLegend = chartConfig.overrideShowLegend;
  const chartOptions: ChartOptions<"line" | "bar"> = createOptions(
    linesConfig,
    chartConfig.timeRange,
    dateLabels,
    chartConfig.title,
    overrideShowLegend,
    chartConfig.showChartTitle,
    tooltipOverrideCallback
  );
  linesConfig.forEach((line: IChartSingleLineConfig) => {
    if (line.values && line.values.length > 0) {
      const dataset = chartData.datasets.find(
        (element) => element.label === line.displayName
      );
      if (line.isShowLine === false && dataset !== undefined) {
        dataset.data = [];
        return;
      }
      if (dataset) {
        let labelIndex = 0;
        let dataIndex = 0;
        const missingTime = [];

        while (
          chartData.labels &&
          labelIndex < chartData.labels.length &&
          dataIndex < line.values.length
        ) {
          const valueTime = line.values[dataIndex];
          const labelTime = chartConfig.timeRange.start.add(labelIndex * intervalInSeconds, "seconds").toDate();
          if (labelTime.getTime() === valueTime.time.getTime()) {
            dataset.data.push(valueTime.value);
            dataIndex++;
            labelIndex++;
          } else if (valueTime.time > labelTime) {
            missingTime.push(labelTime);
            dataset.data.push(NaN);
            labelIndex++;
          } else {
            console.warn(
              "We have too much data in input graphs " +
              valueTime.time +
              " doesn't exists. More info= " +
              labelTime +
              " " +
              line.id
            );
            dataIndex++;
          }
        }
        if (missingTime.length > 0) {
          console.warn(
            "Missing  data " + missingTime.length + " for " + line.id,
            missingTime
          );
        }
      } else {
        console.log("chart data not received yet for " + line.id);
      }
    } else {
      console.error("chart line not defined ");
    }
  });

  function createLinesConfiguration(
    lineNames: string[],
    rangeTime: { start: Dayjs; end: Dayjs },
    linesConfig: IChartSingleLineConfig[] | ITrendLine[],
    intervalInSeconds: number
  ): { chartData: ChartData<"line" | "bar">, dateLabels: Dayjs[] } {
    lineNames.forEach((lineName) => {
      const configLine = linesConfig.find(
        (line) => line.displayName === lineName
      );
      const dataset: LineDataset = {
        label: lineName,
        tag: (configLine as ITrendLine)?.tag ?? "",
        data: [],
        fill: !!configLine?.isLineFill,
        backgroundColor: configLine?.color ?? COLORS.DARK_BLUE_PRIMARY,
        borderWidth: CHART_STYLE_CONFIG.borderWidth,
        borderDash: configLine?.isLineDashed ? [5, 15] : [],
        borderColor: configLine?.color ?? COLORS.DARK_BLUE_PRIMARY,
        pointBackgroundColor: configLine?.color ?? COLORS.DARK_BLUE_PRIMARY,
        pointRadius: CHART_STYLE_CONFIG.pointRadius,
        tension: 0.1,
        yAxisID: configLine?.isCombinedYAxis ? "defaultYAxis" : lineName,
        pointBorderWidth: 0.4,
        showLine: configLine?.isShowLine,
      };
      internalChart.datasets.push(dataset);
    });
    const { labels, dates } = splitTime(rangeTime, intervalInSeconds);
    internalChart.labels = labels;
    return { chartData: internalChart, dateLabels: dates };
  }

  if (chartConfig.chartType === ChartTypeEnum.line) {
    return (
      <Line
        data={chartData as ChartData<"line">}
        options={chartOptions}
        height={chartConfig.chartHeight}
      />
    );
  }
  return (
    <Bar
      data={chartData as ChartData<"bar">}
      options={chartOptions}
      height={chartConfig.chartHeight}
    />
  );
};


function formatForTooltip(time: Dayjs, differenceInDays: number): string {
  if (differenceInDays <= 1) {
    return time.format(DAYJS_HOUR_TO_MINUTE_FORMAT);
  }
  return time.format(DAYJS_MONTH_TO_MINUTE_FORMAT);
}

function formatForTickLabel(time: Dayjs, differenceInDays: number): string {
  if (differenceInDays <= 1) {
    return time.format(DAYJS_HOUR_TO_MINUTE_FORMAT);
  }
  if (differenceInDays > 1 && differenceInDays <= 4) {
    return time.format(DAYJS_MONTH_TO_MINUTE_FORMAT);
  }
  return time.format(DAYJS_MONTH_TO_DAY_FORMAT);
}

function getVisibleLabelIndices(labels: Dayjs[], numberOfLabelsToShow = 4) {
  const indexesOfRoundHoursInLabelsArr = labels
    .map((labelAsDate, index) => ({ labelAsDate, index }))
    .filter(({ labelAsDate: d }) => d.minute() === 0 && d.second() === 0)
    .map(({ index }) => index);

  const labelsToUse =
    indexesOfRoundHoursInLabelsArr.length >= numberOfLabelsToShow
      ? indexesOfRoundHoursInLabelsArr
      : labels.map((_, index) => index);

  const step = Math.ceil(labelsToUse.length / numberOfLabelsToShow);
  return new Set(labelsToUse.filter((_, index) => index % step === 0));
}

export default SolarGikStyledChart;
