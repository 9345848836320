
import { FC } from 'react';
import { parse } from '@messageformat/parser';
import { ComponentType, IIssueMetadata, ISingleDeviceIssue, UrgencyLevel } from '../DTOs';
import { IIssueMetadataMap } from '../Models';
import { TrackerNameById } from '../../trackers/TrackerName';
import LightTooltipOnEllipsis from '../../../SolarGikLib/tooltip/LightTooltipOnEllipsis';
import classes from './DescriptionCell.module.css';

interface IDescriptionCellProps {
    issue: ISingleDeviceIssue;
    issuesMetadata: IIssueMetadataMap;
}

export const DescriptionCell: FC<IDescriptionCellProps> = ({ issue, issuesMetadata }) => {
    const issueMetadata = issuesMetadata[issue.issueType];
    const descriptionTokens = parse(issueMetadata.openStateDescription);
    const elements: JSX.Element[] = descriptionTokens.map((token, index) => {
        if (token.type === "argument") {
            if (token.arg === "ComponentNumber") {
                return issueMetadata.componentType === ComponentType.Tracker
                    ? <TrackerNameById key={index} id={issue.deviceId} />
                    : <span key={index}>{issue.deviceId}</span>;
            }
            if (token.arg === "ScreeningTimeMinutes") {
                return <span key={index}>{formatScreeningTimes(issue, issueMetadata)}</span>;
            }
        }
        return <span key={index}>{token.ctx.text}</span>;
    });
    return <LightTooltipOnEllipsis
        title={elements}
        customTooltipSx={{ padding: "1.2em", fontSize: 14 }}
    >
        <div className={classes.description}>
            {elements}
        </div>
    </LightTooltipOnEllipsis>;
};

function formatScreeningTimes(issue: ISingleDeviceIssue, issueMetadata: IIssueMetadata) {
    switch (issue.urgencyLevel) {
        case UrgencyLevel.High:
            return issueMetadata.screeningTimesMinutes?.high?.toString() ?? "";
        case UrgencyLevel.Medium:
            return issueMetadata.screeningTimesMinutes?.medium?.toString() ?? "";
        case UrgencyLevel.Low:
            return issueMetadata.screeningTimesMinutes?.low?.toString() ?? "";
        default:
            return "";
    }
}

