import { FC, useEffect, useState } from "react";

import { selectedTagsColumns } from "./SelectedTagsTableColumns";
import { TABLE_WAITING_FOR_USER_CHOICES_MESSAGE } from "../../../common/ConstantValues";
import Table from "../../../common/table/Table";
import { IChartSingleLineConfig } from "../../data_point/models/TagChartModel";
import { ITrendLine } from "../TrendsModel";
import { ChartTypeEnum } from "../../../SolarGikLib/Model";
import { useSiteNamesFormatter } from "../../siteName/SiteNameComponent";
interface ISelectedTagsTableModel {
  chartType: ChartTypeEnum;
  linesConfig: ITrendLine[];
}
const SelectedTagsTable: FC<ISelectedTagsTableModel> = ({ chartType, linesConfig }) => {
  const siteNameFormatter = useSiteNamesFormatter();
  const [rowsData, setRowsData] = useState<IChartSingleLineConfig[]>([]);
  const createTableRows = () => {
    const temp: IChartSingleLineConfig[] = [];
    linesConfig.map((line) => {
      const data = {
        ...line,
        chartType: chartType,
      };
      temp.push(data);
    });
    setRowsData(temp);
  };

  useEffect(() => {
    createTableRows();
  }, [linesConfig]);

  return (
    <Table
      hideToolbar
      hideFooter
      hideHeaders
      hideBorder
      isHideGridColumns
      getRowHeight={() => 80}
      rows={rowsData}
      columns={selectedTagsColumns(siteNameFormatter)}
      noRowsMessage={TABLE_WAITING_FOR_USER_CHOICES_MESSAGE}
      customUI={{
        "& .MuiDataGrid-overlayWrapperInner": {
          display: "flex",
          justifyContent: "center",
        },
      }}
    />
  );
};
export default SelectedTagsTable;
