import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import classes from "./Emergency.module.css";
import {
  getDesiredEmergencyStatusAsync,
  setDesiredEmergencyStatusAsync,
} from "./EmergencyApi";
import { EmergencyState, IEmergencyStatus } from "./EmergencyModel";
import { getEmergencyOptionListConfig } from "./EmergencyOptionListConfig";
import { repetitivePolling } from "../../../common/AsyncUtils";
import SolarGikButton from "../../../SolarGikLib/Button";
import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  ErrorIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import { List } from "../../../SolarGikLib/Lists/List";
import { IListItem } from "../../../SolarGikLib/Lists/ListModels";
import SgPopover from "../../../SolarGikLib/popover/SgPopover";
import {
  filledBlueButton,
  outlinedWhiteButton,
} from "../../../SolarGikLib/styles/ButtonsStyle";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import DarkTooltip from "../../../SolarGikLib/tooltip/DarkTooltip";
import APP_CONFIG from "../../app/configuration/AppConfig";
import { RootState } from "../../app/Store";
import { UserAccessType } from "../../user/UserStore";
import { addNewUserRecord } from "../../user_records/UserRecordsAPI";
import { selectSiteId } from "../../sites/SiteStore";

const Emergency = () => {
  const siteId = useSelector(selectSiteId);
  const user = useSelector((state: RootState) => state.user);
  const showSelectReason = user.userType >= UserAccessType.Engineer;

  const [open, setOpen] = useState<boolean>(false);
  const [isInEmergency, setIsInEmergency] = useState<boolean>(false); //default state for UI
  const [emergencyChanged, setEmergencyChanged] = useState<boolean>(false); //change between of sent and on
  const [activeItem, setActiveItem] = useState<IListItem>();
  const [emergencyStateText, setEmergencyStateText] = useState<string>("");
  const [popoverTitleText, setPopoverTitleText] = useState<string>("");
  const [emergencyTooltipText, setEmergencyTooltipText] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const emergencyStatusRef = useRef<IEmergencyStatus>({
    DesiredState: false,
    ReportedState: false,
  });

  useEffect(() => {
    if (user.userType < UserAccessType.ViewDashboard) {
      return;
    }
    const cntrlr = new AbortController();
    repetitivePolling(
      'getEmergencyStatusAsync',
      getEmergencyStatusAsync,
      APP_CONFIG.milliSecBetweenGetTags,
      APP_CONFIG.milliSecBetweenFailedRequests,
      cntrlr.signal
    );
    return () => {
      cntrlr.abort();
    };
  }, []);

  const handleEmergencyOpen = () => {
    setIsError(false);
    togglePopover();
  };

  const handleTurnOnEmergency = async () => {
    if (await handleChangeEmergencyStatus()) {
      let description = 'Site sent to safe mode by the user';
      if (activeItem?.title) {
        description += ` (${activeItem.title})`;
      }
      createEmergencyReasonUserRecords(
        'Safe mode activated',
        description)
    }
  }

  const handleTurnOffEmergency = async () => {
    if (await handleChangeEmergencyStatus()) {
      createEmergencyReasonUserRecords(
        'Safe mode deactivated',
        'Site released from safe mode by the user'
      )
    }
  }

  const handleGetEmergencyError = () => {
    if (!open) {
      setIsError(true);
      setEmergencyTooltipText(
        `Failed to get emergency state.
        contact support for help`
      );
    }
  }

  const createEmergencyReasonUserRecords = async (reason: string, description: string) => {
    const userRecordDto = {
      EventDate: new Date().getTime(),
      Title: reason,
      Reporter: user.userName,
      ComponentType: "System",
      ComponentName: '-',
      RecordType: "Other",
      Description: description,
      IsEngineerOnly: false,
    };
    await addNewUserRecord(siteId, userRecordDto);
  };

  const handleChangeEmergencyStatus = async () => {
    handleClose();
    setIsError(false);
    try {
      await setDesiredEmergencyStatusAsync(
        siteId,
        !isInEmergency,
        !isInEmergency ? activeItem?.title : undefined
      );
      emergencyStatusRef.current.DesiredState = !isInEmergency;
      await getEmergencyStatusAsync();
      return true;
    } catch (error) {
      console.error(error);
      setIsError(true);
      setEmergencyTooltipText(
        `Failed to change emergency state.
         contact support for help`
      );
      return false;
    }
  };

  const getEmergencyStatusAsync = async () => {
    try {
      const emergencyStatus = await getDesiredEmergencyStatusAsync(siteId);
      emergencyStatusRef.current = emergencyStatus;
      if (!emergencyStatus || emergencyStatus.ReportedState === null) {
        handleGetEmergencyError();
        return;
      }
      //sent state
      if (
        emergencyStatus.DesiredState !== null && (emergencyStatus.DesiredState !== emergencyStatus.ReportedState)) {
        setIsInEmergency(true);
        setEmergencyChanged(true);
        //on state
      } else if (
        emergencyStatus.DesiredState === emergencyStatus.ReportedState &&
        emergencyStatus.DesiredState === true
      ) {
        setIsInEmergency(true);
        setEmergencyChanged(false);
        //off state
      } else {
        setIsInEmergency(false);
        setEmergencyChanged(false);
      }
    } catch (error) {
      handleGetEmergencyError();
    }
  };

  const emergencyState = (): void => {
    setPopoverTitleText("Are you sure you want to exit Emergency state?");
    if (
      isInEmergency &&
      emergencyStatusRef.current.DesiredState ===
      emergencyStatusRef.current.ReportedState
    ) {
      setEmergencyStateText(EmergencyState.EmergencyOn);
    } else if (
      emergencyStatusRef.current.DesiredState !==
      emergencyStatusRef.current.ReportedState
    ) {
      setEmergencyStateText(EmergencyState.EmergencySent);
    } else {
      setEmergencyStateText(EmergencyState.EmergencyOff);
      setPopoverTitleText(showSelectReason
        ? "What is the reason for this Emergency?"
        : "Are you sure you want to activate Emergency state?");
    }
  };

  const setTooltipForStateChange = (): void => {
    if (emergencyStatusRef.current.ReportedState) {
      setEmergencyTooltipText(
        "User emergency is ON.\n Click the button to deactivate\n the emergency state"
      );
    } else {
      setEmergencyTooltipText(
        "User emergency is Off.\nClick the button to activate\n the emergency state"
      );
    }
  };

  const setTooltipForStateOnOff = (): void => {
    if (emergencyStatusRef.current.ReportedState) {
      setEmergencyTooltipText(
        "Request sent.It will take some moments.\nClick the button to activate the emergency state"
      );
    } else {
      setEmergencyTooltipText(
        "Request sent.It will take some moments.\nClick the button to deactivate the emergency state"
      );
    }
  };

  const emergencyTooltip = (): void => {
    if (
      emergencyStatusRef.current.DesiredState ===
      emergencyStatusRef.current.ReportedState
    ) {
      setTooltipForStateChange();
    } else {
      setTooltipForStateOnOff();
    }
  };

  useEffect(() => {
    emergencyState();
    emergencyTooltip();
  }, [emergencyChanged, isInEmergency]);

  const [isHovered, setIsHovered] = useState(false);
  const EmergencyIcon = getIcons(ErrorIcons.EmergencyError, IconCategory.Error);
  const EmergencyIconHover = getIcons(
    ErrorIcons.EmergencyErrorHover,
    IconCategory.Error
  );

  const isDisable = user.userType < UserAccessType.Operator;
  const optionListConfig = getEmergencyOptionListConfig(user.userType);
  const togglePopover = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  const EmergencyButton = () => {
    return (
      <DarkTooltip title={emergencyTooltipText}>
        <div className={classes["emergency-btn-div"]}>
          <Button
            className={`${classes["emergency-btn"]} ${TextEnum.h6} ${classes[`emergency-${isInEmergency}`]
              } ${isDisable ? classes.disabled : ""}`}
            variant="outlined"
            onClick={handleEmergencyOpen}
            disabled={isDisable}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            endIcon={
              isError ? (
                isHovered ? (
                  <EmergencyIconHover />
                ) : (
                  <EmergencyIcon />
                )
              ) : null
            }
          >
            <span className={TextEnum.h6}>{emergencyStateText}</span>
          </Button>
        </div>
      </DarkTooltip>
    );
  };

  return (
    <SgPopover
      open={open}
      onClose={handleClose}
      TriggerButton={EmergencyButton()}
      title={popoverTitleText}
    >
      {emergencyStateText === EmergencyState.EmergencyOff ? (
        <>
          {
            showSelectReason && <List
              list={optionListConfig}
              selectedItem={activeItem}
              setSelectedItem={setActiveItem}
            />
          }
          <div className={classes["button-container"]}>
            <SolarGikButton
              style={filledBlueButton}
              text={showSelectReason ? "Send" : "Activate"}
              isDisabled={showSelectReason && activeItem === undefined}
              onClickFunc={handleTurnOnEmergency}
            />
          </div>
        </>
      ) : (
        <div
          className={`${classes["button-container"]} ${classes["button-container-cancel-emergency"]}`}
        >
          <SolarGikButton
            style={filledBlueButton}
            text="Yes"
            onClickFunc={handleTurnOffEmergency}
          />
          <SolarGikButton
            style={outlinedWhiteButton}
            text="No"
            onClickFunc={handleClose}
          />
        </div>
      )}
    </SgPopover>
  );
};

export default Emergency;
