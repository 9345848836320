import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import Table from "../../../common/table/Table";
import { AppDispatch, RootState } from "../../app/Store";
import getActiveAlertTableColumns from "./ActiveAlertTableColumns";
import useNumberOfRowsToDisplay from "../../../common/Hooks/useNumberOfRowsToDisplay";
import classes from "./Alerts.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import { TextEnum } from "../../../SolarGikLib/TextStyles";
import { selectSiteDateTimeFormatter, selectSiteId, selectSiteTrackersMap } from "../../sites/SiteStore";
import { fetchActiveAlerts } from "../../app/store/FaultsStore";
import { AlertUnion } from "../Models";
import AlertDetailsDialog from "./detailsDialog/AlertDetailsDialog";
import { useIs150PercentScreen } from "../../../common/WindowUtils";
import { LoadingState } from "../../app/LoadingState";

const rowsSurroundingHeight = 276;
const rowHeight = 45;
const refreshIntervalMs = 60_000;

const ActiveAlerts = () => {
    const pageSize = useNumberOfRowsToDisplay(rowsSurroundingHeight, rowHeight);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
    const [detailsAlarm, setDetailsAlarm] = useState<AlertUnion | undefined>();
    const siteId = useSelector(selectSiteId);
    const siteAlerts = useSelector((state: RootState) => state.multiSitesFaults.activeFaults[siteId]?.alerts);
    const alertsMetadata = useSelector((state: RootState) => state.multiSitesFaults.metadata.alerts);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (!siteId) {
            return;
        }
        dispatch(fetchActiveAlerts(siteId));
        const interval = setInterval(
            () => dispatch(fetchActiveAlerts(siteId)),
            refreshIntervalMs
        );
        return () => clearInterval(interval);
    }, [siteId]);
    useEffect(() => {
        if (siteAlerts?.loadingState != LoadingState.Error) {
            setAlertMessage(undefined);
            return;
        }
        setAlertMessage({
            text: "Failed to load alerts",
            severity: "error",
        });
    }, [siteAlerts]);

    const handlePaginationChange = (params: { page: number; pageSize: number; }) => {
        setCurrentPage(params.page + 1);
    };

    const tableData: AlertUnion[] = useMemo(() => [
        ...siteAlerts?.data?.aggregatedAlerts.map(alert => ({ ...alert, kind: "aggregated" } as AlertUnion)) || [],
        ...siteAlerts?.data?.deviceAlerts.map(alert => ({ ...alert, kind: "single-device" } as AlertUnion)) || []
    ], [siteAlerts]);
    const trackerNamesMap = useSelector(selectSiteTrackersMap);
    const dtFormatter = useSelector(selectSiteDateTimeFormatter);
    const is150PercentScreen = useIs150PercentScreen();
    const columnsDef = useMemo(
        () => [
            ...getActiveAlertTableColumns(alertsMetadata, trackerNamesMap, dtFormatter, is150PercentScreen),
            {
                field: "details",
                width: 74,
                headerName: "Details",
                sortable: false,
                filterable: false,
                renderCell: (params) => <IconButton onClick={() => setDetailsAlarm(params.row)} size="small">
                    <InfoOutlined />
                </IconButton>,
            }
        ] as GridColDef<AlertUnion>[],
        [alertsMetadata, trackerNamesMap, dtFormatter, is150PercentScreen]
    );

    const noAlerts = tableData.length == 0 && siteAlerts?.loadingState === LoadingState.Complete;
    return (<>
        <AlertDetailsDialog
            alert={detailsAlarm}
            metadata={detailsAlarm ? alertsMetadata?.[detailsAlarm.alertType] : undefined}
            open={!!detailsAlarm}
            onClose={() => setDetailsAlarm(undefined)}
        />
        <div className={classes.container}>
            <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
            {noAlerts && (
                <div className={classes["no-alerts-text"]}>
                    <span className={`${TextEnum.h3}`}>Congratulations!</span>
                    <span className={`${TextEnum.h2}`}> No alerts in the site!</span>
                </div>
            )}
            {!noAlerts && siteAlerts?.loadingState !== LoadingState.Error && (
                <Table
                    hideToolbar
                    loading={siteAlerts?.loadingState !== LoadingState.Complete}
                    rows={tableData}
                    columns={columnsDef}
                    customUI={{
                        "& .MuiDataGrid-main": {
                            borderLeft: "0.3rem solid #63a7fd",
                            borderRadius: "25px",
                        },
                    }}
                    initialState={
                        {
                            sorting: {
                                sortModel: [{ field: "startTime", sort: "desc" }],
                            },
                        }
                    }
                    paginationMode="client"
                    onPaginationModelChange={handlePaginationChange}
                    paginationModel={{
                        page: currentPage - 1,
                        pageSize: pageSize,
                    }}
                    hideFooterPagination={tableData.length <= pageSize}
                    getRowId={(alert: AlertUnion) => `${alert.alertType}_${alert.id}`}
                    rowHeight={rowHeight}
                    columnHeaderHeight={45}
                    rowSelection={false}
                />
            )}
        </div>
    </>
    );
};

export default ActiveAlerts;
