import { FC, useState } from "react";

import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Dayjs } from "dayjs";
import { createAndDownloadBlobFile } from "../../common/DownloadFileUtils";

import { IHistoryTagsParams } from "./models/TagChartModel";
import { IStringToTimeValueListDictionary } from "./models/TagsModels";
import getIcons from "../../SolarGikLib/icons/Icons";
import {
  FileCommandsIcons,
  IconCategory,
} from "../../SolarGikLib/icons/IconsModels";
import { DAYJS_YEAR_TO_SECOND_FORMAT } from "../app/DayjsUtils";
import { RootState } from "../app/Store";
import { getTagsHistoryAsync } from "../app/TagsAPI";
import { UserAccessType } from "../user/UserStore";

interface IExportHistoryTagsToCsvFileProps {
  params: IHistoryTagsParams;
  chartTitle: string;
}

interface IRow {
  data: string[];
}

interface CsvData {
  rows: IRow[];
}

const CSV_HEADER_LENGTH = 2;

const ExportHistoryTagsToCsvFile: FC<IExportHistoryTagsToCsvFileProps> = ({
  params,
  chartTitle,
}) => {
  const userType: UserAccessType = useSelector((state: RootState) => state.user.userType);
  const siteName = useSelector((state: RootState) => state.site.visualInfo.displayName);
  const [loading, setIsLoading] = useState<boolean>(false);
  const timeValuesArr: CsvData = {
    rows: [],
  };
  const startTimeStr = params.range.start.format(DAYJS_YEAR_TO_SECOND_FORMAT);
  const endTimeStr = params.range.end.format(DAYJS_YEAR_TO_SECOND_FORMAT);

  const fetchAndProcessHistoryAsync = async () => {
    if (loading) {
      return;
    }
    try {
      setIsLoading(true);
      const apiResponse = await getTagsHistoryAsync(params);
      const csvData = formatDataToCSV(apiResponse);
      const filename = `${siteName} Average history data of ${chartTitle} over time`;
      const quoteEscapeColumn = (column: string) => `"${column.replace('"', '""')}"`;
      const csvFormattedData = csvData.map((row) => row.map(quoteEscapeColumn).join(",")).join("\n");
      createAndDownloadBlobFile(csvFormattedData, filename, "text/csv", ".csv");

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getTimeArray = () => {
    let pointer = params.range.start;
    const endTime = params.range.end;
    const returnArray: Dayjs[] = [];
    let index = 0;
    while (pointer <= endTime) {
      timeValuesArr.rows[index] = { data: [] } as IRow;
      returnArray.push(pointer);
      pointer = pointer.add(params.samplingIntervalInSeconds, "seconds");
      index++;
    }
    return returnArray;
  };

  const formatDataToCSV = (
    apiResponse: IStringToTimeValueListDictionary
  ): string[][] => {
    if (!apiResponse || Object.keys(apiResponse).length === 0) {
      return [["no data!"]] as string[][];
    }
    const times = getTimeArray();
    const csvHeaders = Object.keys(apiResponse);
    csvHeaders.unshift("Time");
    const csvTableData: string[][] = Array.from(
      { length: times.length + CSV_HEADER_LENGTH },
      () => Array(csvHeaders.length).fill("")
    );
    csvTableData[0][0] = `Site Name: ${siteName}, Time Range : ${startTimeStr} - ${endTimeStr}`; //site name and time range row
    csvTableData[1] = csvHeaders; //headers row
    times.forEach((time, timeIndex) => {
      csvTableData[timeIndex + CSV_HEADER_LENGTH][0] = time.format(DAYJS_YEAR_TO_SECOND_FORMAT);
      Object.values(apiResponse).forEach((valueTimeArr, columnIndex) => {
        if (valueTimeArr.length > 0) {
          let dictionaryDataUsedCounter = 0;
          for (const element of valueTimeArr) {
            const currentTime = element.time.getTime();
            if (time.valueOf() === currentTime) {
              dictionaryDataUsedCounter++;
              csvTableData[timeIndex + CSV_HEADER_LENGTH][columnIndex + 1] =
                element.value.toString(); // Set the value for this time and header
            }
          }
          if (
            dictionaryDataUsedCounter !== times.length ||
            dictionaryDataUsedCounter !== valueTimeArr.length ||
            times.length !== valueTimeArr.length
          ) {
            console.log(
              `The expected number of values was ${times.length},
                 The API returned ${valueTimeArr.length} values.
                 ${dictionaryDataUsedCounter} of these values matched the expected times.`
            );
          }
        }
      });
    });
    return csvTableData;
  };

  const DownloadIcon = getIcons(
    FileCommandsIcons.Download,
    IconCategory.FileCommands
  );
  const DownloadIconDisabled = getIcons(
    FileCommandsIcons.DownloadDisabled,
    IconCategory.FileCommands
  );

  return (
    <>
      {userType >= UserAccessType.Viewer && (
        <IconButton disabled={loading} onClick={fetchAndProcessHistoryAsync}>
          {!loading ? <DownloadIcon /> : <DownloadIconDisabled />}
        </IconButton>
      )}
    </>
  );
};

export default ExportHistoryTagsToCsvFile;
