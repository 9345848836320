import axios from "axios";
import APP_CONFIG from "./configuration/AppConfig";
import { isValidDate } from "../../common/DateUtils";
import { IHistoryTagsParams } from "../data_point/models/TagChartModel";
import {
  IStringToTimeValueDictionary,
  IStringToTimeValueListDictionary,
  IValueTime,
  ISiteToTagList,
  IMultiSiteStringToTimeValueDictionary,
} from "../data_point/models/TagsModels";

interface IValueTimeDto {
  value: number;
  time: number;
}
interface IStringToTimeValueListDictionaryDto {
  [key: string]: IValueTimeDto[];
}
interface IStringToTimeValueDictionaryDto {
  [key: string]: IValueTimeDto;
}
interface IMultiSiteStringToTimeValueDictionaryDto {
  [siteId: string]: IStringToTimeValueDictionaryDto;
}

export const adminClearTagsCacheSendAsync = async () => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiClearCache;
  try {
    await axios.post(url);
  } catch (error) {
    console.error(error);
    throw new Error("SYSTEM_ERROR -> unable to clearCache");
  }
};

export const getSpecificTagsLatestAsync = async (
  siteId: string,
  tags: string[]
): Promise<IStringToTimeValueDictionary> => {
  if (tags.length == 0) {
    console.error("No tags to get!!");
    throw new Error("No tags to get!!");
  }
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetSpecificTagsLatest;
  const params = { siteId: siteId, tags: tags };
  const startTime = new Date().getTime();
  const httpResponse = await axios.post<IStringToTimeValueDictionaryDto>(
    url,
    params
  );
  if (APP_CONFIG.isAnalysisApiActive) {
    const endTime = new Date().getTime();
    const duration = endTime - startTime;
    console.log(
      `api - getSpecificTagsLatestAsync took ${duration}ms to respond.`
    );
  }
  if (Object.entries(httpResponse.data).length == 0) {
    console.error("received empty object from getSpecificTagsLatestAsync");
  }
  return convertToTimeValueDictionary(httpResponse.data);
};

export const getMultiSiteSpecificTagsLatest = async (
  sitesWithTags: ISiteToTagList
): Promise<IMultiSiteStringToTimeValueDictionary> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiGetMultiSiteSpecificTagsLatest;

  const httpResponse =
    await axios.post<IMultiSiteStringToTimeValueDictionaryDto>(url, {
      sitesWithTags,
    });

  if (Object.entries(httpResponse.data).length === 0) {
    throw new Error(
      "received empty object from getMultiSiteSpecificTagsLatest"
    );
  }
  logMissingSitesAndTags(sitesWithTags, httpResponse.data);
  return convertMultiSiteToTimeValueDictionary(httpResponse.data);
};

const logMissingSitesAndTags = (
  sitesWithTags: ISiteToTagList,
  serverResponse: IMultiSiteStringToTimeValueDictionaryDto
) => {
  const missingTagsBySite: { [siteId: string]: string[] } = {};
  for (const [siteId, tags] of Object.entries(sitesWithTags)) {
    const responseForSite = serverResponse[siteId];
    if (!responseForSite) {
      console.error(`No data received for site: ${siteId}`);

      continue;
    }
    const missingTags = tags.filter((tag) => !(tag in responseForSite));
    if (missingTags.length > 0) {
      missingTagsBySite[siteId] = missingTags;
    }
  }
  if (Object.keys(missingTagsBySite).length > 0) {
    for (const [siteId, tags] of Object.entries(missingTagsBySite)) {
      console.error(`Missing tags for site ${siteId}: ${tags.join(", ")}`);
    }
  }
};

interface IHistoryTagsParamsDto {
  siteId: string;
  tags: string[];
  fromDateUtc: number;
  toDateUtc: number;
  samplingInterval: string;
}
export const getTagsHistoryAsync = async (
  params: IHistoryTagsParams
): Promise<IStringToTimeValueListDictionary> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetHistory;
  if (
    params == undefined ||
    !isValidDate(params.range.start) ||
    !isValidDate(params.range.end) ||
    params.range.start.isSame(params.range.end) ||
    params.range.start.isAfter(params.range.end)
  ) {
    throw (
      "Invalid dates: from and to dates are required to be different and valid " +
      JSON.stringify(params)
    );
  }
  //Convert to DTO
  const intervalStr = convertSecondsToDtoTimeSpanString(
    params.samplingIntervalInSeconds
  );
  const paramsDto: IHistoryTagsParamsDto = {
    fromDateUtc: params.range.start.valueOf(),
    toDateUtc: params.range.end.valueOf(),
    siteId: params.siteId,
    tags: params.tags,
    samplingInterval: intervalStr,
  };
  const httpResponse = await axios.post<IStringToTimeValueListDictionaryDto>(
    url,
    paramsDto
  );
  if (Object.entries(httpResponse.data).length === 0) {
    throw "received empty object on tag history fetching";
  }
  return convertToTimeValueListDictionary(httpResponse.data);
};

const convertToTimeValueDictionary = (
  stringToValueTimeDictionary: IStringToTimeValueDictionaryDto
): IStringToTimeValueDictionary => {
  const output: IStringToTimeValueDictionary = {};

  Object.entries(stringToValueTimeDictionary).forEach(([id, valueTime]) => {
    output[id] = {
      time: new Date(valueTime.time),
      value: valueTime.value,
    };
  });
  return output;
};

const convertTimeValueDtoToValueList = (
  timeValueListDto: IValueTimeDto[]
): IValueTime[] => {
  return timeValueListDto.map((timeValue) => ({
    time: new Date(timeValue.time),
    value: timeValue.value,
  }));
};
const convertMultiSiteToTimeValueDictionary = (
  multiSiteData: IMultiSiteStringToTimeValueDictionaryDto
): IMultiSiteStringToTimeValueDictionary => {
  const output: IMultiSiteStringToTimeValueDictionary = {};

  Object.entries(multiSiteData).forEach(([siteId, siteData]) => {
    output[siteId] = convertToTimeValueDictionary(siteData);
  });

  return output;
};

const convertToTimeValueListDictionary = (
  input: IStringToTimeValueListDictionaryDto
): IStringToTimeValueListDictionary => {
  const output: IStringToTimeValueListDictionary = {};

  Object.entries(input).forEach(([id, timeValueList]) => {
    const valueList = convertTimeValueDtoToValueList(timeValueList);
    output[id] = valueList;
  });
  return output;
};
const convertSecondsToDtoTimeSpanString = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = time % 60;
  return `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
    "0" + seconds
  ).slice(-2)}`;
};