import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../app/Store";
import { siteSlice, selectSiteId } from "../sites/SiteStore";
import getIcons from "../../SolarGikLib/icons/Icons";
import { GeneralIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { isPageVisible } from "./NavigationUtils";
import { PageNames } from "../../pages/PageNames";
import Layout from "./Layout";

interface ContentPageRouterProps {
  children: ReactElement;
}

const ContentPageRouter: FC<ContentPageRouterProps> = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const siteIdFromStore = useSelector(selectSiteId);
  const siteIds = useSelector((state: RootState) => state.user.siteIds);
  const [siteIdFromRoute, setSiteIdFromRoute] = useState<string>('');
  const multiSitesMetadata = useSelector((state: RootState) => state.multiSitesMetadata);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const newSiteIdFromRoute = pathSegments[0];
    if (newSiteIdFromRoute !== siteIdFromRoute ||
      newSiteIdFromRoute !== siteIdFromStore) {
      setSiteIdFromRoute(newSiteIdFromRoute);
      if (!multiSitesMetadata.sites[newSiteIdFromRoute]) {
        return;
      }
      const pathReminder = pathSegments.slice(1).join("/");
      const pageName = pathSegments[1];
      const pageVisibleOnNewSite = isPageVisible(
        pageName,
        multiSitesMetadata.sites[newSiteIdFromRoute].visualInfo
      );
      const navigationTarget = pageVisibleOnNewSite
        ? `/${newSiteIdFromRoute}/${pathReminder}`
        : `/${newSiteIdFromRoute}/${PageNames.Dashboard}`;
      navigate(navigationTarget, { replace: true });
    }
  }, [location]);

  useEffect(() => {
    const selectedSiteMetadata = multiSitesMetadata.sites[siteIdFromRoute];
    if (selectedSiteMetadata) {
      dispatch(siteSlice.actions.setSiteMetadata(selectedSiteMetadata));
    }
  }, [multiSitesMetadata, siteIdFromRoute]);

  if (!siteIds.some((id) => id === siteIdFromRoute)) {
    return <span>404 - no such site &quot;{siteIdFromRoute}&quot;</span>;
  }

  if (siteIdFromRoute === siteIdFromStore) {
    return <Layout>{children}</Layout>;
  }

  const SolargikIcon = getIcons(
    GeneralIcons.SolarGikLogo,
    IconCategory.General
  );

  return (
    <div className="third-height">
      <SolargikIcon fontSize={"large"} />
    </div>
  );
};

export default ContentPageRouter;
