import { IHistoryTagsParams } from "./models/TagChartModel";
import { IStringToTimeValueListDictionary } from "./models/TagsModels";

export const validateResponse = (
  response: IStringToTimeValueListDictionary,
  params: IHistoryTagsParams,
  intervalInSeconds: number
) => {
  const numberOfExpectedDataPoints =
    Math.floor(params.range.end.diff(params.range.start, "seconds") / intervalInSeconds + 60);

  if (Object.keys(response).length == 0) {
    throw "No data";
  } else if (Object.values(response)[0].length > numberOfExpectedDataPoints) {
    console.error(
      "recieved too many values per tag " +
      Object.values(response)[0].length +
      " > " +
      numberOfExpectedDataPoints
    );
  } else if (Object.keys(response).length > params.tags.length) {
    throw (
      "number of tags in response are greater than number of tags in request res=" +
      Object.keys(response).length +
      " : " +
      params.tags.length
    );
  } else if (Object.keys(response).length < params.tags.length) {
    console.warn(
      "number of tags in response are less than number of tags in request res=" +
      Object.keys(response).length +
      " : " +
      params.tags.length
    );
  }
  Object.keys(response).forEach((tagName: string) => {
    if (!params.tags.includes(tagName)) {
      throw (
        "recieved unexpected tag " +
        tagName +
        "\n " +
        JSON.stringify(Object.keys(response)) +
        "\nTags:" +
        JSON.stringify(params.tags)
      );
    }
  });
};
