import { GridColDef } from "@mui/x-data-grid";

import { ISingleDeviceIssue, ComponentType, UrgencyLevel } from "../DTOs";
import { IIssueMetadataMap } from "../Models";
import { convertEnumToIconName } from "../Utils";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import IconAndTextCellItem from "../../multi_sites/cellItems/IconAndTextCellItem";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { NO_VALUE_PLACEHOLDER } from "../../../common/ConstantValues";
import { TrackerNameById, tryGetTrackerName } from "../../trackers/TrackerName";
import { DescriptionCell } from "./DescriptionCell";
import { TrackerNamesMap } from "../../sites/SiteModels";
import DateTimeFormatter from "../../app/DateTimeFormatter";
import { createTimeCell } from "../../../common/table/CellFactory";

export function getIssusTableColumns(
  issuesMetadata: IIssueMetadataMap | undefined, trackerNamesMap: TrackerNamesMap, dtFormatter: DateTimeFormatter, isSmallScreen: boolean
): GridColDef<ISingleDeviceIssue>[] {
  return [
    {
      ...createTimeCell((row) => row.startTime, dtFormatter, isSmallScreen),
      field: "startTime",
      headerName: "Time opened"
    },
    {
      field: "urgencyLevel",
      headerName: "Severity",
      flex: 0.5,
      valueGetter: (params) => humanizeEnumValue(UrgencyLevel[params.row.urgencyLevel]) ?? NO_VALUE_PLACEHOLDER,
      renderCell: (params) => {
        return (
          <IconAndTextCellItem
            Icon={getIcons(
              convertEnumToIconName(params.row.urgencyLevel),
              IconCategory.Error
            )}
            text={params.value}
          />
        );
      },
    },
    {
      field: "issueType",
      headerName: "Issue type",
      flex: 0.7,
      valueGetter: (params) => issuesMetadata?.[params.row.issueType].name,
    },
    {
      field: "componentType",
      headerName: "Component",
      flex: 0.5,
      valueGetter: (params) => issuesMetadata ? ComponentType[issuesMetadata[params.row.issueType].componentType] : '',
    },
    {
      field: "deviceId",
      headerName: "Device Id",
      flex: 0.5,
      valueGetter: (params) => {
        if (issuesMetadata === undefined) {
          return '';
        }
        if (issuesMetadata[params.row.issueType].componentType === ComponentType.Tracker) {
          return tryGetTrackerName(params.row.deviceId, trackerNamesMap);
        }
        return params.row.deviceId;
      },
      renderCell: (params) => {
        if (issuesMetadata === undefined) {
          return '';
        }
        if (issuesMetadata[params.row.issueType].componentType === ComponentType.Tracker) {
          return <TrackerNameById id={params.row.deviceId} />;
        }
        return <span>{params.value}</span>;
      }
    },
    {
      field: "description",
      headerName: "Detail",
      flex: 2,
      renderCell: (params) => issuesMetadata && <DescriptionCell issue={params.row} issuesMetadata={issuesMetadata} />,
    },
  ];
}

