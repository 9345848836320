import React, { FC } from "react";
import { useSelector } from "react-redux";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../features/app/Store";
import { sgDayjsLocale } from "../../features/app/DayjsUtils";


interface SgDateTimePickerProps {
    utcValue: Date | null;
    setUtcValue: (value: Date | null) => void;
    label: string;
    className?: string;
}

const SgDateTimePicker: FC<SgDateTimePickerProps> = (props) => {
    const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);
    const handleChange = (value: Dayjs | null) => {
        props.setUtcValue(value?.toDate() ?? null);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={sgDayjsLocale}>
            <DateTimePicker
                ampm={false}
                className={props.className}
                closeOnSelect={true}
                label={props.label}
                defaultValue={dayjs(props.utcValue)}
                value={dayjs(props.utcValue)}
                timezone={siteTimezone}
                onChange={handleChange}
                disableFuture={true}
                timeSteps={{ minutes: 1 }}
            />
        </LocalizationProvider>
    );

}

export default SgDateTimePicker;
