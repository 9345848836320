import { SiteAppType } from "../../sites_configuration/AppConfigModels";

export interface IServiceUrls {
  apiHostUrl: string;
  siteAppsConfigUrlPrefix: string;
  userRecordsUrlPrefix: string;
  siteGatewayUrlPrefix: string;
  historianTagsSystemBaseUrl: string;
  dualUsageUrlPrefix: string;
  maintenanceServiceUrlPrefix: string;
}

export interface IAppConfig
  extends IAppConfigGeneral,
  IAppConfigPerEnvironment { }

export interface IAuthConfig {
  tenantName: string;
  clientId: string;
  authorityDomain: string;
  webApiScope: string;
  signInAuth: string;
}

export interface IAppConfigGeneral {
  portalVersion: string;

  onHoverUpdateIntervalInMilliseconds: number;
  chartDefaultSamplingInterval: string;
  delayFactorOnNoneFocus: number;
  configFileMaxSize: number;

  minRangeSamplingInterval: string; // APP_CONFIG.chartDefaultSamplingInterval
  mediumRangeSamplingInterval: string;
  longRangeSamplingInterval: string;

  defaultExportIntervalInSeconds: number;
  trackersExportIntervalInSeconds: number;
  tagStatusOldSecondsThreshold: number;
  tagStatusInvalidSecondsThreshold: number;

  isAnalysisActive: boolean;
  isAnalysisApiActive: boolean;

  milliSecBetweenFailedRequests: number;
  milliSecBetweenFailedInitRequests: number;
  millisecondsBetweenChartHistoryFailedRequest: number;
  millisecondsBetweenChartHistory: number;
  milliSecBetweenWeatherGif: number;
  milliSecLoaderTimeOut: number;
  milliSecBetweenGetTags: number;
  milliSecBetweenGetHistory: number;
  milliSecBetweenGetTrackers: number;
  milliSecBetweenGetIssues: number;

  apiGetMultiSiteSpecificTagsLatest: string;
  apiGetSpecificTagsLatest: string;
  apiGetHistory: string;
  apiGetSitesHistory: string;
  apiGetMultipleTagsMeta: string;
  apiGetTrackersData: string;
  apiGetSiteMetadata: string;
  apiGetSitesMetadata: string;
  apiGetUserInfo: string;
  apiClearCache: string;
  apiGetConfigList: string;
  apiGetActiveConfig: string;
  apiUpdateConfig: string;
  apiDownloadConfigResource: string;
  apiUploadConfigResource: string;
  apiSetActiveConfigResource: string;
  apiSetMetadata: string;
  apiUploadAgriPlan: string;
  apiDeleteAgriPlan: string;
  apiGetDailyAgriPlan: string;
  apiGetOverallAgriPlan: string;
  apiGetTemplateFileAgriPlan: string;
  apiUploadSprinklersPlan: string;
  apiDownloadSprinklersPlan: string;
  apiDeleteSprinklersPlan: string;
  apiGetSprinklersPlanTemplateFile: string;
  apiGetIsPlanExist: string;
  apiGetSitesActiveIssues: string;
  apiGetSiteMetadataCsv: string;
  apiUploadCsvToSiteMetadata: string;
  apiFeatureFlags: string;

  apiActivateSafeOverride: (siteId: string) => string;
  apiDeactivateSafeOverride: (siteId: string) => string;
  apiGetWeatherSafetyOverrideRemainingTime: (siteId: string) => string;
  apiGetIsWeatherSafetyOverrideActive: (siteId: string) => string;
  apiEmergencyStatus: (siteId: string) => string;
  apiInitTimeScaleDbSchemaForNewSites: (siteId: string) => string;
  apiSendTrackersToMaintenance: (siteId: string) => string;
  apiReleaseTrackersFromMaintenance: (siteId: string) => string;
  apiSendTrackersToFreeze: (siteId: string) => string;
  apiResetTrackers: (siteId: string) => string;
  apiSendTrackersToHoming: (siteId: string) => string;
  apiForceTrackersElevation: (siteId: string) => string;
  apiAddNewUserRecord: (siteId: string) => string;
  apiQueryUserRecords: (siteId: string) => string;
  apiDeleteUserRecord: (siteId: string, recordId: number) => string;

  apiUpdateSiteControlToUseDas: (siteId: string) => string;
  apiUpdateSiteControlConfigToUseTagsHarvester: (siteId: string) => string;
  apiGenerateTagsHarvesterConfig: (siteId: string) => string;
  apiGetIotDevices: string;
  apiGetModuleStatusOnDevice: (
    siteId: string,
    deviceId: string,
    siteApp: SiteAppType
  ) => string;
  apiEnsureSiteModuleTypeExists: (
    siteId: string,
    siteApp: SiteAppType,
    deviceId: string
  ) => string;
  apiAddOrUpdateTagInIotDevice: string;
}

export interface IAppConfigPerEnvironment {
  serviceUrls: IServiceUrls;
  authConfig: IAuthConfig;
  clarityProjectId?: string;
}

const SecondMs = 1000;
const MinuteMs = 60 * SecondMs;

export const GENERAL_APP_CONFIG: IAppConfigGeneral = {
  isAnalysisActive: false,
  isAnalysisApiActive: false,
  tagStatusOldSecondsThreshold: 20 * 60,
  tagStatusInvalidSecondsThreshold: 7200, // 2 hours
  milliSecBetweenGetTags: 5 * SecondMs,
  milliSecBetweenGetHistory: 15 * MinuteMs,
  milliSecBetweenGetTrackers: 30 * SecondMs,
  milliSecBetweenGetIssues: 30 * SecondMs,

  portalVersion: process.env.REACT_APP_SG_VERSION || "unknown version",
  configFileMaxSize: 10000000,
  chartDefaultSamplingInterval: "00:15:00",
  delayFactorOnNoneFocus: 5,
  onHoverUpdateIntervalInMilliseconds: SecondMs,

  minRangeSamplingInterval: "00:10:00", // APP_CONFIG.chartDefaultSamplingInterval
  mediumRangeSamplingInterval: "01:00:00",
  longRangeSamplingInterval: "12:00:00",

  defaultExportIntervalInSeconds: 30,
  trackersExportIntervalInSeconds: 60,

  milliSecBetweenFailedRequests: 10 * SecondMs,
  milliSecBetweenFailedInitRequests: 3 * SecondMs,
  millisecondsBetweenChartHistoryFailedRequest: 10 * SecondMs,
  millisecondsBetweenChartHistory: 600 * SecondMs,
  milliSecBetweenWeatherGif: 60 * SecondMs,
  milliSecLoaderTimeOut: 30 * SecondMs,

  apiGetSitesActiveIssues: `/Issues/Sites`,
  apiGetMultiSiteSpecificTagsLatest: "/Tags/getMultiSiteSpecificTagsLatest",
  apiGetSpecificTagsLatest: "/Tags/getSpecificTagsLatest",
  apiGetHistory: "/Tags/getHistoryTags",
  apiGetSitesHistory: "/Tags/getHistoryTagsPerSite",
  apiGetMultipleTagsMeta: "/Tags/getMultipleTagsMetaData",
  apiGetTrackersData: "/TrackerData/getTrackersData",
  apiGetSiteMetadata: "/SiteMetadata/getSiteMetadata",
  apiGetSitesMetadata: "/SiteMetadata/getSitesMetadata",
  apiGetUserInfo: "/User/getUserInfo",
  apiClearCache: "/Admin/clearTagsCache",
  apiGetConfigList: "/Query",
  apiGetActiveConfig: "/Active",
  apiUpdateConfig: "/SiteConfiguration",
  apiDownloadConfigResource: "/Resource",
  apiUploadConfigResource: "/Resource",
  apiSetActiveConfigResource: "/Active",
  apiSetMetadata: "/Resource/Metadata",
  apiUploadAgriPlan: `/ShadingPlan`,
  apiDeleteAgriPlan: `/ShadingPlan`,
  apiGetDailyAgriPlan: `/ShadingPlan/CurrentDay`,
  apiGetOverallAgriPlan: `/ShadingPlan/OverallPlan`,
  apiGetTemplateFileAgriPlan: `/ShadingPlan/GetTemplateFile`,
  apiUploadSprinklersPlan: `/SprinklerPlan`,
  apiDownloadSprinklersPlan: `/SprinklerPlan`,
  apiDeleteSprinklersPlan: `/SprinklerPlan`,
  apiGetSprinklersPlanTemplateFile: "/SprinklerPlan/GetTemplateFile",
  apiGetIsPlanExist: "/SprinklerPlan",
  apiGetSiteMetadataCsv: "/SiteMetadata/CsvTable",
  apiUploadCsvToSiteMetadata: "/SiteMetadata/CreateComponentsFromCsvTable",
  apiAddOrUpdateTagInIotDevice: "/site/AddTagToIotDevice",
  apiGetIotDevices: "/site/GetIotHubDevices",
  apiFeatureFlags: "/features",

  apiActivateSafeOverride: (siteId: string) => `/WeatherSafetyOverride/${siteId}/activate`,
  apiDeactivateSafeOverride: (siteId: string) => `/WeatherSafetyOverride/${siteId}/deactivate`,
  apiGetWeatherSafetyOverrideRemainingTime: (siteId: string) => `/WeatherSafetyOverride/${siteId}/remainingTime`,
  apiGetIsWeatherSafetyOverrideActive: (siteId: string) => `/WeatherSafetyOverride/${siteId}/isActive`,
  apiEmergencyStatus: (siteId: string) => `/site/${siteId}/emergency`,
  apiInitTimeScaleDbSchemaForNewSites: (siteId: string) =>
    `/tags-system/recreate/${siteId}?force=${false}`,
  apiSendTrackersToMaintenance: (siteId: string) =>
    `/site/${siteId}/trackers/commands/maintenance`,
  apiReleaseTrackersFromMaintenance: (siteId: string) =>
    `/site/${siteId}/trackers/commands/release`,
  apiSendTrackersToFreeze: (siteId: string) =>
    `/site/${siteId}/trackers/commands/freeze`,
  apiResetTrackers: (siteId: string) =>
    `/site/${siteId}/trackers/commands/reset`,
  apiSendTrackersToHoming: (siteId: string) =>
    `/site/${siteId}/trackers/commands/homing`,
  apiForceTrackersElevation: (siteId: string) =>
    `/site/${siteId}/trackers/commands/forceElevation`,
  apiAddNewUserRecord: (siteId: string) => `/site/${siteId}/UserRecord`,
  apiQueryUserRecords: (siteId: string) => `/site/${siteId}/UserRecord`,
  apiDeleteUserRecord: (siteId: string, recordId: number) =>
    `/site/${siteId}/UserRecord/${recordId}`,

  apiUpdateSiteControlToUseDas: (siteId: string) =>
    `/UpdateSiteControlConfigToUseDas/${siteId}`,
  apiUpdateSiteControlConfigToUseTagsHarvester: (siteId: string) =>
    `/UpdateSiteControlConfigToUseTagsHarvester/${siteId}`,
  apiGenerateTagsHarvesterConfig: (siteId: string) =>
    `/GenerateTagsHarvesterConfig/${siteId}`,
  apiGetModuleStatusOnDevice: (
    siteId: string,
    deviceId: string,
    siteApp: SiteAppType
  ) => `/site/${siteId}/${deviceId}/${siteApp}/runtimeStatus`,
  apiEnsureSiteModuleTypeExists: (
    siteId: string,
    siteApp: SiteAppType,
    deviceId: string
  ) => `/site/${siteId}/${deviceId}/module/${siteApp}`,
};
