import {
  ForceTrackersElevationResultCodeEnum,
  ReleaseTrackersFromMaintenanceResultCodeEnum,
  ResetTrackersResultCodeEnum,
  SendTrackersToFreezeResultCodeEnum,
  SendTrackersToHomingResultCodeEnum,
  SendTrackersToMaintenanceResultCodeEnum,
  TrackersCommandType,
  McsCommandNotImplementedStatusCode
} from "../TrackersModels";
import { humanizeEnumValue } from "../../../common/EnumUtils";

export const convertEnumResultCodeToString = (resultCode: number, command: TrackersCommandType): string => {
  if (resultCode === McsCommandNotImplementedStatusCode) {
    return "The command is not implemented in the current MCS version";
  }
  const enumMap = {
    [TrackersCommandType.ReleaseTrackersFromMaintenance]: ReleaseTrackersFromMaintenanceResultCodeEnum,
    [TrackersCommandType.SendTrackersToMaintenance]: SendTrackersToMaintenanceResultCodeEnum,
    [TrackersCommandType.SendTrackersToFreeze]: SendTrackersToFreezeResultCodeEnum,
    [TrackersCommandType.ResetTrackers]: ResetTrackersResultCodeEnum,
    [TrackersCommandType.SendTrackersToHoming]: SendTrackersToHomingResultCodeEnum,
    [TrackersCommandType.ForceTrackersElevation]: ForceTrackersElevationResultCodeEnum,
  };
  const enumValue = enumMap[command]?.[resultCode];
  return humanizeEnumValue(enumValue) || "Unknown Result Code";
};