import { FC } from "react";
import { humanizeEnumValue } from "../../../../common/EnumUtils";
import { UrgencyLevel } from "../../DTOs";
import classes from "./UrgencyCell.module.css";
import { NO_VALUE_PLACEHOLDER } from "../../../../common/ConstantValues";
import { mapSverityToIcons } from "../../Utils";


interface UrgencyCellProps {
  urgencyLevel: UrgencyLevel;
}

const UrgencyCell: FC<UrgencyCellProps> = ({ urgencyLevel }) => {
  const Icon = mapSverityToIcons(urgencyLevel);
  const severity = humanizeEnumValue(UrgencyLevel[urgencyLevel]) ?? NO_VALUE_PLACEHOLDER;
  return (
    <div className={classes.container}>
      <div className={classes["icon-container"]}>
        <Icon width={24} height={24} />
      </div>
      <div className={classes["severity-container"]}>{severity}</div>
    </div>
  );
};

export default UrgencyCell;
