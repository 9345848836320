import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as enGbLocale from 'dayjs/locale/en-gb';
import dayjs from "dayjs";

export const DAYJS_YEAR_TO_SECOND_FORMAT = "DD MMM YYYY HH:mm:ss";
export const DAYJS_YEAR_TO_MINUTE_FORMAT = "DD MMM YYYY HH:mm";
export const DAYJS_YEAR_TO_DAY_FORMAT = "DD MMM YYYY";
export const DAYJS_MONTH_TO_MINUTE_FORMAT = "MMM DD HH:mm";
export const DAYJS_MONTH_TO_DAY_FORMAT = "MMM DD";
export const DAYJS_HOUR_TO_MINUTE_FORMAT = "HH:mm";

function generateDayjsLocale() {
  const customLocaleName = 'sg-locale';
  const localeObject: ILocale = {
    ...enGbLocale,
    name: customLocaleName,
    formats: {
      ...enGbLocale.formats,
      L: 'DD MMM YYYY'
    }
  };
  dayjs.locale(localeObject, undefined, true);

  return customLocaleName;
}

dayjs.extend(utc);
dayjs.extend(timezone);

export const sgDayjsLocale = generateDayjsLocale();