import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { subMonths } from "date-fns";
import {
  ISitesIssueCounters, IIssueMetadata, IAlertMetadata,
  ComponentType, ISingleDeviceIssue,
  IAlertsHistory, ISingleDeviceAlert, IAggregateAlert
} from "../../faults/DTOs";
import {
  getActiveAlerts, getActiveIssues, getAlertsHistory,
  getFaultsMetadata, getSitesActiveIssuesCounters
} from "../../faults/API";
import { RootState } from "../Store";
import {
  ActiveIssuesByComponentCategory, IIssueMetadataMap, ComponentCategory,
  IAlertMetadataMap
} from "../../faults/Models";
import { LoadingState } from "../LoadingState";

interface FaultStoreState {
  activeFaults: {
    [siteId: string]: {
      alerts?: {
        loadingState: LoadingState,
        data?: {
          deviceAlerts: ISingleDeviceAlert[];
          aggregatedAlerts: IAggregateAlert[];
        }
      },
      issues?: {
        loadingState: LoadingState,
        data?: ISingleDeviceIssue[]
      }
    } | undefined
  },
  alertsHistory: {
    [siteId: string]: {
      loadingState: LoadingState
      value?: IAlertsHistory
    } | undefined
  },
  issueCounters: {
    loading: LoadingState,
    data: ISitesIssueCounters
  };
  metadata: {
    loading: LoadingState,
    issues?: IIssueMetadataMap;
    alerts?: IAlertMetadataMap;
  };
}

const initialState: FaultStoreState = {
  activeFaults: {},
  alertsHistory: {},
  issueCounters: {
    loading: LoadingState.None,
    data: {},
  },
  metadata: {
    loading: LoadingState.None,
  },
};

export const multiSitesFaultsSlice = createSlice({
  name: "multiSitesFaultsCounters",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveIssues.pending, (state, action) => {
        const siteId = action.meta.arg;
        state.activeFaults[siteId] ??= {};
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.activeFaults[siteId]!.issues = { loadingState: LoadingState.Pending };
      })
      .addCase(fetchActiveIssues.fulfilled, (state, action) => {
        const { siteId, activeIssues } = action.payload;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.activeFaults[siteId]!.issues = {
          loadingState: LoadingState.Complete,
          data: activeIssues
        };
      })
      .addCase(fetchActiveIssues.rejected, (state, action) => {
        const siteId = action.meta.arg;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.activeFaults[siteId]!.issues = { loadingState: LoadingState.Error };
      })
      .addCase(fetchActiveAlerts.pending, (state, action) => {
        const siteId = action.meta.arg;
        state.activeFaults[siteId] ??= {};
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.activeFaults[siteId]!.alerts = { loadingState: LoadingState.Pending };
      })
      .addCase(fetchActiveAlerts.fulfilled, (state, action) => {
        const { siteId, activeAlerts } = action.payload;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.activeFaults[siteId]!.alerts = {
          loadingState: LoadingState.Complete,
          data: activeAlerts
        };
      })
      .addCase(fetchActiveAlerts.rejected, (state, action) => {
        const siteId = action.meta.arg;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.activeFaults[siteId]!.alerts = { loadingState: LoadingState.Error };
      })
      .addCase(fetchFaultsMetadata.pending, (state) => {
        state.metadata.loading = LoadingState.Pending;
      })
      .addCase(fetchFaultsMetadata.fulfilled, (state, action) => {
        const { issues, alerts } = action.payload;
        state.metadata.issues = issues.reduce((accumulator, metadata: IIssueMetadata) => {
          accumulator[metadata.issueType] = metadata;
          return accumulator;
        }, {} as IIssueMetadataMap);
        state.metadata.alerts = alerts.reduce((accumulator, metadata: IAlertMetadata) => {
          accumulator[metadata.alertType] = metadata;
          return accumulator;
        }, {} as IAlertMetadataMap);
        state.metadata.loading = LoadingState.Complete;
      })
      .addCase(fetchFaultsMetadata.rejected, (state) => {
        state.metadata.loading = LoadingState.Error;
      })
      .addCase(fetchActiveIssuesCounters.pending, (state) => {
        state.issueCounters.loading = LoadingState.Pending;
      })
      .addCase(fetchActiveIssuesCounters.fulfilled, (state, action) => {
        state.issueCounters.data = action.payload;
        state.issueCounters.loading = LoadingState.Complete;
      })
      .addCase(fetchActiveIssuesCounters.rejected, (state) => {
        state.issueCounters.loading = LoadingState.Error;
      })
      .addCase(fetchAlertsHistory.pending, (state, action) => {
        state.alertsHistory[action.meta.arg.siteId] = { loadingState: LoadingState.Pending };
      })
      .addCase(fetchAlertsHistory.fulfilled, (state, action) => {
        state.alertsHistory[action.meta.arg.siteId] = {
          loadingState: LoadingState.Complete,
          value: action.payload
        };
      })
      .addCase(fetchAlertsHistory.rejected, (state, action) => {
        if ((action.error.code === "ERR_CANCELED" || action.error.name === "AbortError") &&
          state.alertsHistory[action.meta.arg.siteId]?.loadingState === LoadingState.Pending) {
          return;
        }
        state.alertsHistory[action.meta.arg.siteId] = {
          loadingState: LoadingState.Error,
        };
      });
  },
});

export const fetchActiveIssues = createAsyncThunk(
  "faults/fetchActiveIssues",
  async (siteId: string, { signal }) => {
    const activeIssues = await getActiveIssues(siteId, signal);
    return {
      siteId,
      activeIssues,
    };
  }
);

export const fetchActiveAlerts = createAsyncThunk(
  "faults/fetchActiveAlerts",
  async (siteId: string, { signal }) => {
    const activeAlerts = await getActiveAlerts(siteId, signal);
    return {
      siteId,
      activeAlerts,
    };
  }
);

export const fetchFaultsMetadata = createAsyncThunk(
  "faults/fetchMetadata", getFaultsMetadata);

export const fetchActiveIssuesCounters = createAsyncThunk(
  "faults/fetchActiveIssuesCounters",
  async (params: { resolve: () => void, reject: (err: unknown) => void }, { signal }) => {
    try {
      const res = getSitesActiveIssuesCounters(signal);
      params.resolve();
      return res;
    } catch (err) {
      params.reject(err);
      throw err;
    }
  }
);

export const fetchAlertsHistory = createAsyncThunk(
  "faults/fetchAlertsHistory",
  (args: { siteId: string }, { signal }) => {
    const from = subMonths(new Date(), 6);
    return getAlertsHistory(args.siteId, from, new Date(), signal);
  }
);

export const selectActiveIssuesByComponentCategory = createSelector(
  [
    (state: RootState) => state.multiSitesFaults.metadata,
    (state: RootState, siteId: string) => state.multiSitesFaults.activeFaults[siteId]?.issues?.data,
  ],
  (metadata, activeIssues) => {
    if (!activeIssues || metadata?.issues === undefined) {
      return undefined;
    }
    return mapActiveIssuesByComponentCategory(activeIssues, metadata.issues);
  }
);

function mapActiveIssuesByComponentCategory(
  deviceIssues: ISingleDeviceIssue[], issuesMetadata: IIssueMetadataMap): ActiveIssuesByComponentCategory {
  return deviceIssues.reduce((accumulator, deviceIssue) => {
    const { componentType } = issuesMetadata[deviceIssue.issueType];
    const category = componentTypeToCategory(componentType);
    if (!accumulator[category]) {
      accumulator[category] = [];
    }
    accumulator[category]?.push(deviceIssue);
    return accumulator;
  }, {} as ActiveIssuesByComponentCategory);
}

function componentTypeToCategory(componentType: ComponentType): ComponentCategory {
  switch (componentType) {
    case ComponentType.Inverter:
      return ComponentCategory.Inverter;
    case ComponentType.Tracker:
      return ComponentCategory.Tracker;
    case ComponentType.System:
      return ComponentCategory.System;
    default:
      return ComponentCategory.Auxiliary;
  }
}

export const multiSitesFaultsReducer = multiSitesFaultsSlice.reducer;
