import { FC } from "react";
import { useSelector } from "react-redux";
import classes from './TrackerName.module.css'
import { TrackerNamesMap } from "../sites/SiteModels";
import { selectSiteTrackersMap } from "../sites/SiteStore";

export const TrackerName: FC<{ name: string }> = ({ name }) => {
    return <span className={classes['tracker-name']}>{name}</span>;
};


export const TrackerNameById: FC<{ id: number }> = ({ id }) => {
    const trackerNamesMap = useSelector(selectSiteTrackersMap);
    const name = tryGetTrackerName(id, trackerNamesMap);
    return <TrackerName name={name} />;
};

export const tryGetTrackerName = (id: number, trackerNamesMap: TrackerNamesMap): string => {
    const candidate = trackerNamesMap[id];
    if (candidate && candidate !== "") {
        return candidate;
    }
    return id.toString();
}
