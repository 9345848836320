import { useSelector } from "react-redux";

import classes from "./Weather.module.css";
import { RootState } from "../features/app/Store";
import CardSiteStyledLineChartWrapper from "../features/charts_ui/CardSiteStyledLineChartWrapper";
import {
  CHART_DEFAULT_HEIGHT,
  IRRADIANCE_POWER_TITLE,
  WEATHER_TITLE,
  createIrradiancePowerChartConfig,
  createWeatherChartConfig,
} from "../features/common_chart_config/ChartConfig";
import WeatherDataPointCard from "../features/weather/WeatherDataPointCard";
import WeatherGif from "../features/weather/WeatherGif";
import Card from "../SolarGikLib/cards/Card";

const Weather = () => {
  const siteMetadata = useSelector((state: RootState) => state.site);
  const weatherChartConfig = createWeatherChartConfig(siteMetadata);
  const irradiancePowerChartConfig = createIrradiancePowerChartConfig(siteMetadata);
  const nrtImageUrl = "THIS IS BROKEN, WHEN WE'LL FIX IT IT WONT BE BROKEN. DUH"; //useSelector((state: RootState) => state.site.nrtImageUrl); 
  const cloudTypeImageUrl = "THIS IS BROKEN, WHEN WE'LL FIX IT IT WONT BE BROKEN. DUH"; //useSelector((state: RootState) => state.site.cloudTypeImageUrl); 
  return (
    <div className={classes["grid-container"]}>
      <div className={`${classes["current-weather"]}`}>
        <Card title={"Cloud realtime"}>
          <WeatherGif imageUrl={nrtImageUrl} />
        </Card>
      </div>
      <div className={`${classes["forecast-weather"]}`}>
        <Card title={"Cloud Types"}>
          <WeatherGif imageUrl={cloudTypeImageUrl} />
        </Card>
      </div>
      <div className={`${classes["weather-chart"]}`}>
        <CardSiteStyledLineChartWrapper
          siteId={weatherChartConfig.siteId}
          title={WEATHER_TITLE}
          tagInfo={weatherChartConfig.tags}
          samplingInterval={weatherChartConfig.samplingInterval}
          timeRange={{
            start: weatherChartConfig.fromDate,
            end: weatherChartConfig.toDate,
          }}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={true}
        />
      </div>
      <div className={`${classes["power-chart"]}`}>
        <CardSiteStyledLineChartWrapper
          siteId={siteMetadata.siteId}
          title={IRRADIANCE_POWER_TITLE}
          tagInfo={irradiancePowerChartConfig.tags}
          samplingInterval={irradiancePowerChartConfig.samplingInterval}
          timeRange={{
            start: irradiancePowerChartConfig.fromDate,
            end: irradiancePowerChartConfig.toDate,
          }}
          chartHeight={CHART_DEFAULT_HEIGHT}
          isRepetitiveRequest={true}
        />
      </div>
      <div className={`${classes["weather-sum"]}`}>
        <Card>
          <WeatherDataPointCard />
        </Card>
      </div>
    </div>
  );
};
export default Weather;
