import { MultisiteTableIcon, TrendsIcon } from "../../../SolarGikLib/icons/common/navigation/NavIcons";
import { NavConfig } from "../../navigation/NavigationDrawer/NavigationDrawerModels";
import { UserAccessType } from "../../user/UserStore";

export const NavItemsConfig: NavConfig[] = [
  {
    pageName: "Multisite",
    pagePath: "/MultisiteTable",
    icon: MultisiteTableIcon,
    visiblityFilter: (userAccessType) =>
      userAccessType >= UserAccessType.ViewDashboard,
  },
  {
    pageName: "Trends",
    pagePath: "/Trends",
    icon: TrendsIcon,
    visiblityFilter: (userAccessType) =>
      userAccessType >= UserAccessType.Viewer,
  },
];
