import { createSlice } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import { ITrendState } from "./TrendsModel";
import { ChartTypeEnum } from "../../SolarGikLib/Model";
import { resetSiteSpecificData } from "../app/store/GlobalActions";

const initialState: ITrendState = {
  "": {
    trendConfig: {
      title: "",
      rangeTime: [dayjs(), dayjs()],
      samplingInterval: 0,
      chartType: ChartTypeEnum.line,
    },
    linesConfig: [],
  },
};
export const trendsSlice = createSlice({
  name: "trends",
  initialState: initialState,
  reducers: {
    setTrendConfig: (state, action) => {
      const { id, trendConfig } = action.payload;
      state[id].trendConfig = trendConfig;
    },
    setLinesConfig: (state, action) => {
      const { id, linesConfig } = action.payload;
      state[id].linesConfig = linesConfig;
    },
    removeLine: (state, action) => {
      const { id, lineIdToRemove } = action.payload;
      const updatedLinesConfig = state[id].linesConfig.filter(
        (line) => line.id !== lineIdToRemove
      );
      state[id].linesConfig = updatedLinesConfig;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetSiteSpecificData, () => {
      return initialState;
    });
  },
});

export const trendsReducer = trendsSlice.reducer;
