import { GridColDef } from "@mui/x-data-grid";
import { TrackersStateEnum } from "./TrackersModels";
import { humanizeEnumValue } from "../../common/EnumUtils";
import { convertNumberToTimeSpan } from "../../common/StringUtils";
import TableCellItemNumber from "../../common/table/TableCellItemNumber";
import TableCellItemString from "../../common/table/TableCellItemString";
import BatteryStatus from "./BatteryStatus";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { TrackerName } from "./TrackerName";



export const trackersTableColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.2,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 0.6,
    renderCell: (params) => <TrackerName name={params.value} />,
  },
  {
    field: "currentState",
    headerName: "Current State",
    flex: 1,
    renderCell: (params) => {
      const text = !params
        ? ""
        : (humanizeEnumValue(TrackersStateEnum[params.value]) ?? NO_VALUE_PLACEHOLDER);
      return <span>{text}</span>;
    },
  },
  {
    field: "currentElevation",
    headerName: "Current Elevation °",
    flex: 1,
    renderCell: (params) => <TableCellItemNumber value={params.value} />,
  },
  {
    field: "uptime",
    headerName: "Uptime",
    flex: 1,
    renderCell: (params) => (
      <TableCellItemString
        value={params.value ? convertNumberToTimeSpan(params.value) : null}
      />
    ),
  },
  {
    field: "shadingRatio",
    headerName: "Shading Ratio",
    flex: 1,
    renderCell: (params) => <TableCellItemString value={params.value} />,
  },
  {
    field: "batteryStatus",
    headerName: "Battery Status",
    flex: 2,
    renderCell: (params) => (
      <BatteryStatus
        chargePercentage={params.value.chargePercentage}
        chargingState={params.value.chargingState}
      ></BatteryStatus>
    ),
  },
];