import {
  IAggregateAlert, IAggregateAlertHistory,
  IAlertMetadata, IIssueMetadata, ISingleDeviceAlert,
  ISingleDeviceAlertHistory, ISingleDeviceIssue
} from "./DTOs";

type DiscriminatedSingleDeviceAlert = ISingleDeviceAlert & { kind: 'single-device', history: false };
type DiscriminatedAggregateAlert = IAggregateAlert & { kind: 'aggregated', history: false };
type DiscriminatedSingleDeviceAlertHistory = ISingleDeviceAlertHistory & { kind: 'single-device', history: true };
type DiscriminatedAggregateAlertHistory = IAggregateAlertHistory & { kind: 'aggregated', history: true };

export type AlertUnion = DiscriminatedSingleDeviceAlert | DiscriminatedAggregateAlert | DiscriminatedSingleDeviceAlertHistory | DiscriminatedAggregateAlertHistory;

export enum ComponentCategory {
  Inverter = 1,
  Tracker = 2,
  System = 3,
  Auxiliary = 4,
}

export interface IIssueMetadataMap {
  [id: number]: IIssueMetadata
}

export interface IAlertMetadataMap {
  [id: number]: IAlertMetadata
}

export interface ISitesIssueCounters {
  [siteId: string]: {
    highSeverity: number;
    mediumSeverity: number;
    lowSeverity: number;
  };
}

export interface ISiteActiveAlerts {
  deviceAlerts: ISingleDeviceAlert[];
  aggregatedAlerts: IAggregateAlert[];
}

export type ActiveIssuesByComponentCategory = {
  [key in ComponentCategory]?: ISingleDeviceIssue[]
}