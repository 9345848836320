import { Fragment, useEffect, useRef, useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { useSelector } from "react-redux";

import dayjs, { Dayjs } from "dayjs";
import classes from "./History.module.css";
import useDebounce from "../common/Hooks/useDebounce";
import { btnStyling } from "../common/Mui/MuiStyling";
import { RootState } from "../features/app/Store";
import { getSamplingInterval } from "../features/data_point/charts/ChartTagsUtils";
import DateTimeRange from "../features/History/DateTimeRange";
import HistoryChartsSection from "../features/History/HistoryChartsSection";
import { TextEnum } from "../SolarGikLib/TextStyles";

const History = () => {
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);
  const siteTimeNow = dayjs().tz(siteTimezone);
  const isNightTimeRef = useRef(false);
  const [requestedRangeTime, setRequestedRangeTime] = useState({
    start: siteTimeNow,
    end: siteTimeNow,
  });
  const debouncedRequestedRangeTime = useDebounce(requestedRangeTime, 500);

  useEffect(() => handleRangeChange(requestedRangeTime.start, requestedRangeTime.end), []);

  const handleIncludeNightTimeClicked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    isNightTimeRef.current = event.target.checked;
    handleRangeChange(requestedRangeTime.start, requestedRangeTime.end);
  };

  const handleRangeChange = (startTime: Dayjs, endTime: Dayjs) => {
    const start = isNightTimeRef.current
      ? startTime.startOf("day")
      : startTime.startOf("day").add(4, "hours");
    const end = isNightTimeRef.current ? endTime.endOf("day") : endTime.startOf("day").add(20, "hours");
    setRequestedRangeTime({ start: start, end: end });
  };

  const samplingInterval = getSamplingInterval(
    debouncedRequestedRangeTime.start,
    debouncedRequestedRangeTime.end
  );

  return (
    <Fragment>
      <div className={classes["date-selector"]}>
        <DateTimeRange updateRange={handleRangeChange} />
      </div>
      <FormControlLabel
        control={
          <Checkbox className={classes["checkbox"]} sx={btnStyling} onChange={handleIncludeNightTimeClicked} />
        }
        label={<span className={TextEnum.h4}>{"Include night time"}</span>}
      />
      <HistoryChartsSection
        requestedRangeTime={debouncedRequestedRangeTime}
        samplingInterval={samplingInterval}
      />
    </Fragment>
  );
};
export default History;
