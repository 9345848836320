import axios, { AxiosError } from "axios";

import {
  ITrackerStatus,
  ReleaseTrackersFromMaintenanceResultCodeEnum,
  SendTrackersToMaintenanceResultCodeEnum,
  TrackerElevation,
  SendTrackersToFreezeResultCodeEnum,
  ResetTrackersResultCodeEnum,
  HomingDirection,
  SendTrackersToHomingResultCodeEnum,
  ITrackersCommandResultsMap,
  ITrackersCommandResponse,
  ForceTrackersElevationResultCodeEnum,
  McsCommandNotImplementedStatusCode,
} from "./TrackersModels";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getAllLatestTrackersAsync = async (siteId: string): Promise<ITrackerStatus[]> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetTrackersData;
  const params = { siteId: siteId };
  const httpResponse = await axios.request<ITrackerStatus[]>({ url: url, params: params });
  return httpResponse.data;
};

export const sendTrackersToMaintenance = async (siteId: string, trackersIds: number[], targetElevation: TrackerElevation, reason: string): Promise<ITrackersCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiSendTrackersToMaintenance(siteId);
  const params = {
    TargetElevationDegrees:
      targetElevation.value * (targetElevation.direction === "West" ? 1 : -1),
    TrackersList: trackersIds,
    Reason: reason,
  };
  return await sendCommand(trackersIds, url, params, SendTrackersToMaintenanceResultCodeEnum.UnexpectedError);
};

export const releaseTrackersFromMaintenance = async (siteId: string, trackersIds: number[]): Promise<ITrackersCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiReleaseTrackersFromMaintenance(siteId);
  const params = { TrackersList: trackersIds };
  return await sendCommand(trackersIds, url, params, ReleaseTrackersFromMaintenanceResultCodeEnum.UnexpectedError);
};

export const sendTrackersToFreeze = async (siteId: string, trackersIds: number[], reason: string): Promise<ITrackersCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiSendTrackersToFreeze(siteId);
  const params = { TrackersList: trackersIds, Reason: reason };
  return await sendCommand(trackersIds, url, params, SendTrackersToFreezeResultCodeEnum.UnexpectedError);
};

export const resetTrackers = async (siteId: string, trackersIds: number[],): Promise<ITrackersCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiResetTrackers(siteId);
  const params = { TrackersList: trackersIds };
  return await sendCommand(trackersIds, url, params, ResetTrackersResultCodeEnum.UnexpectedError);
};

export const sendTrackersToHoming = async (siteId: string, trackersIds: number[], homingDirection: HomingDirection): Promise<ITrackersCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiSendTrackersToHoming(siteId);
  const params = { TrackersList: trackersIds, HomingDirection: homingDirection };
  return await sendCommand(trackersIds, url, params, SendTrackersToHomingResultCodeEnum.UnexpectedError);
};

export const forceTrackersElevation = async (siteId: string, trackersIds: number[], targetElevation: TrackerElevation,): Promise<ITrackersCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiForceTrackersElevation(siteId);
  const params = {
    TargetElevationDegrees:
      targetElevation.value * (targetElevation.direction === "West" ? 1 : -1),
    TrackersList: trackersIds,
  };
  return await sendCommand(trackersIds, url, params, ForceTrackersElevationResultCodeEnum.UnexpectedError);
};

async function sendCommand(trackersIds: number[], url: string, params: unknown, unexpectedErrorValue: number): Promise<ITrackersCommandResultsMap> {
  try {
    const httpResponse = await axios.post<ITrackersCommandResponse>(url, params);
    return httpResponse.data.results;
  } catch (error) {
    const errorValue = (error as AxiosError).response?.status === 501
      ? McsCommandNotImplementedStatusCode
      : unexpectedErrorValue;
    return Object.fromEntries(trackersIds.map((id) => [id, errorValue]));
  }
}